import React, { Fragment} from 'react';
import { Row, Col } from 'react-bootstrap';
import './Header.scss';
import { useHistory, Link } from "react-router-dom";
import appLogo from '../../assets/img/header/logo.png';
import menuIcon from '../../assets/img/header/menu.png';
import avatar from '../../assets/img/header/avatar.png';

import { useSelector } from 'react-redux';
import { parseMenuItems } from '../../containers/Sidebar/Sidebar';
import BgSwitch from '../BgToggleSwitch/BgSwitch';
import Notify from '../UI/Notification/Alert';
import NotificationComponent from '../Notifications/Notification';
import SearchBar from '../SearchBar/Search';
import { LazyImage } from '../CourseItem/LazyLoadImage';

const Header = () => {
  const history = useHistory();
  /**
   * Return a string with a capitalied first letter
   * @param string
   */
  const ucfirst = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

  const currentPage = useSelector(state => state.coreState.currentPage);
  const goBack = useSelector(state => state.coreState.setBackButton);
  const userData = useSelector(state => state.userState.user);



  const toggleMenu = () => {
    const sidebar = document.querySelector('.sidebar').classList;
    // const notificationIcon = document.querySelector('.notification').classList;
    if (sidebar.contains('d-none')) {
      sidebar.add('d-block');
      sidebar.remove('d-none');
      // avatarIcon.add('d-none');
      // notificationIcon.add('d-none');
    } else {
      sidebar.remove('d-block');
      sidebar.add('d-none');
      // avatarIcon.add('d-block');
      // notificationIcon.add('d-block');
    }
  };

  const goTo = () => {
    history.push(goBack.location || '/');
  };
  // const showSubscribeNow = userData &&
  //   userData.latest_package_subscription &&
  //   userData.latest_package_subscription.expired &&
  //   location.pathname !== '/subscribe-package';
  return (
    <Fragment>
      <Row id="header">
        <Col md={3} id="logoHolder" className="d-lg-none">
          <img src={menuIcon}
            onClick={toggleMenu}
            alt="logo"
            id="mobileMenu"
            className="mobileMenu" />
          <img src={appLogo}
            alt="avatar"
            id="appLogo"
            className="d-block"
            onClick={goTo}/>
        </Col>
        <Col lg={9} id="pageTitle" className="d-none d-lg-flex align-items-center ">
          {goBack.action ?
            <span href="#" className="mr-4 backbutton" onClick={goTo}>
              <img alt="avatar" width="28"
                height="28"
                src={require('../../assets/icons/back-arrow.png')} />
            </span> : ''}
          <span className='page-title'>{ucfirst(currentPage)}</span>
        </Col>
        <Col md={3} id="iconHolder">
          <SearchBar/>
          <NotificationComponent/>
          <Link
            to="/profile">

            <LazyImage
              src={userData && userData.image ? userData.image : avatar}
              alt="logo"
              className={'rounded-circle avatar  d-none d-lg-block'}/>

          </Link>
          <BgSwitch />
        </Col>

      </Row>
      <Col sm={4} className="d-none d-lg-none h-100 sidebar p-0" >
        <ol className="sidebarOl pl-3" style={{    position: 'fixed',
          height: '100%'
        }}>
          {parseMenuItems(true)}
        </ol>
      </Col>
      <div className="d-block d-lg-none mb-1 px-4 notificationHeader" >
        <Notify />
      </div>
      {/*{ showSubscribeNow ?*/}
      {/*  <Row>*/}
      {/*    <Col className="p-0 px-md-3">*/}
      {/*      <div className="p-3" style={{backgroundColor: '#DA291C', color: '#fff'}}>*/}
      {/*        Your subscription has expired. To continue,*/}
      {/*        <Link to="/subscribe-package" className="pl-1" style={{color: '#FFE001'}}>*/}
      {/*          click here to renew now*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*  </Row> : null*/}
      {/*}*/}
      <Row className={goBack.action ?
        ' active page-title-holder d-block d-lg-none' : 'page-title-holder d-block d-lg-none'}>
        <Col lg={12} id="pageTitle" className="d-block mt-3 mb-2">
          {goBack.action ?
            <span href="#" className="mr-4 backbutton" onClick={goTo}>
              <img width="28" height="28" alt="avatar"
                src={require('../../assets/icons/back-arrow.png')} /></span> : ''
          }

          {
            (currentPage === 'community' && window.innerWidth < 1024) ? '' :
              <span className='page-title'>{ucfirst(currentPage)}</span>
          }
        </Col>
      </Row>

    </Fragment>
  );
};

export default Header;
