import React, { Component, lazy, Suspense } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Community from "../../../components/Community/Community";
import Header from "../../../components/Header/Header";
import PasswordPopup from "../../../components/PasswordPopup/PasswordPopup";
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import PasswordEdit from "../../../components/ProfileCardEdit/ChangePassword";
import Notify from "../../../components/UI/Notification/Alert";
import DashboardOnboardingScreen from "../../../components/Walkthrough/BigCards";
import { emailNotification } from "../../../constants/general";
import cookie from "../../../helpers/cookie";
import socket, { SOCKET_ENDPOINTS } from "../../../helpers/socket";
import { extractQueryParams } from "../../../helpers/url";
import utilityHelper from "../../../helpers/utility";
import {
  setNotifications,
  SET_PAGE_BACK_BUTTON,
  showAlert,
  SHOW_SNACKBAR,
} from "../../../store/actions/coreActions";
import {
  getCompletedCoursesCount,
  getPendingCoursesCount,
  resendWelcomeMail,
} from "../../../store/actions/userActions";
import PageLoader from "../../PageLoader/PageLoader";
import Sidebar from "../../Sidebar/Sidebar";
import Calendar from "../Calendar/Calendar";
import NotFoundPage from "../NotFound/NotFound";
import PaymentComplete from "../Pricing/PaymentComplete";
import Pricing from "../Pricing/Pricing";
import CourseDetail from "../StudentCourseDetail/CourseDetail";
import StudentCourses from "../StudentCourses/StudentCourses";
import StudentHome from "../StudentHome/StudentHome";
import Subscribe from "../Subscribe/Subscribe";

const Classroom = lazy(() => import("../../Classroom/Classroom"));
class Dashboard extends Component {
  state = {
    isLoading: true,
  };

  constructor() {
    super();
    this.isAuthenticated = utilityHelper.isAuthenticated();
  }

  componentDidMount() {
    utilityHelper.setTitleAndScrollToTop("Dashboard");
    this.init();
    document.querySelector("#header #pageTitle .page-title").innerText =
      "Dashboard";
    document.querySelector(
      ".page-title-holder #pageTitle .page-title"
    ).innerText = "Dashboard";
    this.getNotifications();
    this.props.setBack();
    this.queryParams = extractQueryParams(window.location.href);
    if (this.queryParams.message) {
      this.props.notify(decodeURI(this.queryParams.message));
    }
  }

  getNotifications() {
    socket.on(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, (data) => {
      this.props.setNotifications(data);
    });
    socket.emit(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, {
      accessToken: cookie.get("accessToken"),
      filter: "not-read",
    });
    setInterval(function () {
      socket.emit(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, {
        accessToken: cookie.get("accessToken"),
        filter: "not-read",
      });
    }, 60 * 1000);
  }
  init() {
    //get currentTheme
    const currentTheme = localStorage.getItem("theme") || "dark-theme";
    if (currentTheme === "dark-theme") {
      const toggle = document.querySelector(".bgToggle");
      toggle.checked = true;
    }
    document.body.classList.add(currentTheme);

    if (this.props.user.is_active === false) {
      const config = {
        message: emailNotification,
        title: "Account Activation Notification.",
        type: "warning",
        useButton: {
          buttonClass: "sme-btn-primary no-border ",
          text: "Resend Email",
          action: () => this.props.resendWelcomeMail(),
          loading: false,
        },
      };
      this.props.alert(config);
    }

    this.props.getCompletedCourseCount();
    this.props.getPendingCourseCount();
  }

  render() {
    return (
      <Container fluid>
        {this.isLoading ? <PageLoader /> : <DashboardOnboardingScreen />}
        <PasswordPopup />
        <Suspense fallback={<PageLoader />}>
          <Row className="app-content align-items-start">
            <Sidebar />
            <Col>
              <Header />
              <div className="d-none d-lg-block">
                <Notify />
              </div>

              <Switch>
                <Route path="/" component={StudentHome} exact />
                <Route path="/courses/:id" component={CourseDetail} />
                <Route path="/courses" component={StudentCourses} exact />
                <Route path="/pricing" component={Pricing} exact />
                <Route
                  path="/subscribe/:courseId"
                  component={Subscribe}
                  exact
                />
                <Route path="/profile" component={ProfileCard} exact />
                <Route path="/settings" component={PasswordEdit} exact />
                <Route path="/community" component={Community} exact />
                <Route path="/calendar" component={Calendar} />
                <Route path="/classroom" component={Classroom} />
                <Route
                  path="/payment-success"
                  component={PaymentComplete}
                  exact
                />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Col>
          </Row>
        </Suspense>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (params) =>
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { config: { message: params } },
      }),
    alert: (params) => dispatch(showAlert(params)),
    resendWelcomeMail: () => dispatch(resendWelcomeMail()),
    setBack: () =>
      dispatch({
        type: SET_PAGE_BACK_BUTTON,
        payload: { action: false, location: "/" },
      }),
    setNotifications: (params) => dispatch(setNotifications(params)),
    getCompletedCourseCount: () => dispatch(getCompletedCoursesCount()),
    getPendingCourseCount: () => dispatch(getPendingCoursesCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
