import React, { Fragment} from 'react';
import {Alert, Button, Row, Col } from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import { clearAlert } from "../../../store/actions/coreActions";
import Loader from '../../Loader/Loader';
import './Alert.scss';

const Notify = ()=> {
  // const [loading, setLoading] = useState(false);
  const { open, config } = useSelector(
    state => state.coreState.alert
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearAlert());
  };
  return (
    <Row>
      <Col md={12} className="mx-auto">
        <Alert show={open} variant={config.type} onClose={handleClose} dismissible>
          <Alert.Heading>{config && config.title  ? config.title  : "" }</Alert.Heading>
          <p dangerouslySetInnerHTML={{__html: config.message || ''}} className="alert-content" style={{fontWeight:'400'}}>
          </p>
          {
            config.useButton  ? 
              <Fragment>
                <br/>
                
                <div className="d-flex justify-content-start action ">
                  <Button onClick={() => { 
                    config.useButton.action();
                  }} 
                  className={config.useButton.buttonClass} 
                  // variant={`outline-${config.type}`} 
                  disabled={config.useButton.loading} >
                    <div className=" d-flex align-items-center justify-items-between ">
                      {config.useButton.text}
                      {config.useButton.loading ? <Loader  scale="0.5" /> : null }
                    </div>
                  </Button>
                </div>
              </Fragment>: null
          }
          
        </Alert>
  
    
      </Col>
    </Row>);
};

export default Notify;