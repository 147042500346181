import React, {Fragment } from 'react';
import './PageTitle.scss';
import { useSelector } from 'react-redux';


const PageTitle = props => {
  const sectionTitle = useSelector(state => state.coreState.sectionTitle);
  
  
  /**
   * isTabbed is used to display this page title in table format, see community section side bar
   * hasHr is used to add a border-bottom to the titme
   */
  return (
    <Fragment>
      <h3  className={`d-none d-lg-block mt-4 mb-4 align-self-center pageTitle sectionTitle  ${props.isTab ? 'greyText font-12' : 'font-weight-600'}`} >
        {sectionTitle.title || props.title }
        {(props.hasHr || sectionTitle.hasHr) ?
          <hr className="hr" /> : 
          null}
      </h3>
      <h3 className="d-block d-lg-none align-self-center font-weight-600 pageTitle sectionTitle">
        { sectionTitle.title || props.title}
        {(props.hasHr || sectionTitle.hasHr) ? ( <hr className="hr" />) : null}
      </h3>
    </Fragment>
    
  );
};
export default PageTitle;