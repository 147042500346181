import React, { Fragment } from 'react';
import './ProgressBar.scss';

const ProgressBar = props => {
  const completedCourses = (props.courseCompleted && props.courseCompleted.length) || 0;
  const totalRemaining = (props.courseTotal && props.courseTotal.length) || props.courseTotal;
  
  return (
    <Fragment>
      <div className="progressBar">
        <div className="d-flex mb-2">
          <span className="progress-title ml-3" >Progress</span>
          <span>{`${completedCourses}/${completedCourses+totalRemaining}` }</span>
        </div>
        <div className={`${props.className} progress`} >
          <span style={{width: props.progress}}></span>
        </div>
      </div>
    </Fragment>
  );
};
export default ProgressBar;