import React from "react";
import { withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import AppSnackbar from "../components/UI/Snackbar/Snackbar";

import store from "../store";
// import dashboard from "./pages/Dashboard/Dashboard";

import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";

import CheckAuth from "./CheckAuth/CheckAuth";




import Dashboard from "./pages/Dashboard/Dashboard";
// const Classroom = lazy(() => import('./Classroom/Classroom'));


const App = () => {
  
  return (
    <Provider store={store}>
      <AppSnackbar/>
      <div className="App">
        <ErrorBoundary  >
          <main className="App-body min-content-height container-fluid pl-0 pr-0">
            <CheckAuth>
              <Dashboard/>
            </CheckAuth>
          </main>

        </ErrorBoundary>
      </div>
    </Provider>
  );
};

export default withRouter(App);
