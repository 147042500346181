import socketIOClient from "socket.io-client";

const socket = socketIOClient(`${process.env.REACT_APP_BASE_API_URI}/sockets`);

export const SOCKET_ENDPOINTS = {
  TOPIC_PROGRESS: 'topics/progress',
  GET_NOTIFICATIONS: 'notification/update'
};

export default socket;
