import cookie from "./cookie";

export const replaceDynamics = (route, dynamic_obj = {}) => {
  const m = Object.keys(dynamic_obj).reduce((prev, key) => {
    const reg = new RegExp(`@${key}`);
    return prev.replace(reg, dynamic_obj[key] || '');
  }, route) || route;
  return m.toString().trim();
};
/**
 * Update the window location with query param
 * @param {*} key
 * @param {*} value
 */
export const  insertUrlParam = (key, value) =>  {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl = window.location.protocol + "//" +
                  window.location.host +
                  window.location.pathname + '?' +
                  searchParams.toString();
    window.history.pushState({path: newurl}, '', newurl);
    return newurl;
  }
  return null;
};
export const updateQueryParams = (route, params = {}, customIdentifier = '') => {
  let identifier = ['?', '&'];
  if(customIdentifier && !identifier.includes(customIdentifier)) {
    identifier = identifier.concat(customIdentifier);
  }
  Object.keys(params).forEach(key => {
    const value = params[key];
    const re = new RegExp(`([${identifier.join('')}])` + key + '=.*?(&|$)', 'i');
    if(!(value === undefined || value === null)) {
      const separator = customIdentifier || (route.toString().indexOf('?') !== -1 ? '&' : '?');
      if (route.toString().match(re)) {
        route = route.toString().replace(re, '$1' + key + '=' + value + '$2');
      } else {
        route = route + separator + key + '=' + value;
      }
    } else {
      route = route.toString().replace(re, '?');
    }
  });
  return route;
};

export const extractQueryParams = uri => {
  uri = uri ? uri.toString() : "";
  const search  = uri.includes('?') ? uri.split(/\?(.+)/)[1] : '';
  let result = {};
  result = search ? search.split('&').reduce((prev, curr) => {
    const arr = curr.split('=');
    prev[arr[0]] = arr[1];
    return prev;
  }, result) : result;
  return result;
};

export const hardRedirect = url => {
  url = url || window.location.href;
  window.location.replace(url);
};

export const attachAccessTokenToUrlQueryParams = url => {
  const accessToken = cookie.get("accessToken");
  if(accessToken) {
    return updateQueryParams(url, { accessToken });
  }
  return url;
};

export const attachPreviousQuery = (url, checkFor = '') => {
  let updatedUrl = url;
  const query = extractQueryParams((window.location || {}).href || '');
  if(query && (!checkFor || checkFor in query)) {
    updatedUrl = updateQueryParams(url, query);
  }
  return updatedUrl;
};

export default {
  replaceDynamics,
  updateQueryParams,
  extractQueryParams,
  hardRedirect,
  attachPreviousQuery
};
