export const TRIGGER_DASHBOARD_MENU_ITEM = 'dashboard';
export const TRIGGER_COURSE_MENU_ITEM = 'courses';
export const TRIGGER_EVENT_MENU_ITEM = 'events';
export const TRIGGER_CALENDAR_MENU_ITEM = 'calendar';
export const TRIGGER_COMMUNITY_MENU_ITEM = 'community';
export const TRIGGER_PROFILE_MENU_ITEM = 'profile';
export const TRIGGER_SETTINGS_MENU_ITEM = 'settings';
export const TRIGGER_LOGOUT_MENU_ITEM = 'logout';
export const RESET_WALKTHROUGH = 'reset-walk';
export const RESET_SIDEBAR = 'reset-side';
export const SHOW_MODAL = 'show';
export const POSTION_MODAL_TOP_LEFT = 'top-left';
export const POSTION_MODAL_CENTER = 'center';
/**
 * Trigger active class on menu
 * @param {*} menuItem 
 */
export const triggerMenu = menuItem =>{
  
  return dispatch => {
    dispatch({ type: menuItem });
  };
};

/**
 * Reset the walkthrough
 * @param {*} menuItem 
 */
export const hideModal = () =>{
  return dispatch => {
    dispatch({ type: SHOW_MODAL });
  };
};
/**
 * Reset the walkthrough
 * @param {*} menuItem 
 */
export const skipTour = () =>{
  return dispatch => {
    dispatch({ type: RESET_WALKTHROUGH });
  };
};

/**
 * Position the walkthrough modal
 * @param {String} position 
 */
export const positionWalkthrough = position =>{
  return dispatch => {
    dispatch({ type: position });
  };
};

/**
 * Position the walkthrough modal
 * @param {String} position 
 */
export const reset = param =>{
  return dispatch => {
    dispatch({ type: RESET_SIDEBAR, message: param });
  };
};