import React from "react";
import { Col, Row } from "react-bootstrap";
import CourseItem from "../CourseItem/CourseItem";
import Shimmer from "../Shimmer/shimmerArticle";
import Pagination from "../Pagination/Pagination";
import NotFoundPage from "../../containers/pages/NotFound/NotFound";
import apiRoutes from "../../constants/apiRoutes";

const CourseList = ({
  loadingCourse,
  courseListWithSub,
  paginate,
  noCourseMessage,
  noCourseTitle,
  noCourseCTA,
}) => {
  return (
    <div className={`col-12  p-0 `}>
      <Row>
        {loadingCourse ? (
          <div className="col-12 p-0" id="courseWrapper">
            <Row>
              <Shimmer maxCards="3" />
            </Row>
          </div>
        ) : courseListWithSub ? (
          courseListWithSub.courses.map((c, index) => {
            return (
              <Col sm={6} md={4} className="pt-4 " key={index}>
                <CourseItem key={index} course={c} />
              </Col>
            );
          })
        ) : (
          <div className="col-12">
            <NotFoundPage
              title={noCourseTitle || "Course not found!"}
              subtitle={
                noCourseMessage ||
                "Course with the applied filter does not exist"
              }
              goto="/"
              cta={noCourseCTA || "See all courses"}
            />
          </div>
        )}
      </Row>
      {paginate && courseListWithSub ? (
        <Pagination
          url={apiRoutes.COURSE_LIST_WITH_SUB}
          list={courseListWithSub}
          updateWho="courseWithSub"
          title="See more courses"
        />
      ) : null}
    </div>
  );
};

export default CourseList;
