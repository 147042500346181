import React, { useState, useEffect } from 'react';
import {Card, Button} from 'react-bootstrap';
import './UpcomingEvents.scss';
import '../../assets/scss/custom-buttons.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SET_PAGE_BACK_BUTTON, SET_PAGE_SECTION_TITLE } from '../../store/actions/coreActions';

import { loadEvents, loadUpcomingEvents } from '../../store/actions/eventsActions';
import { smeFormatDate, getValidUrl } from '../../helpers/utility';
import NoEventView from './noEvents';
const EventItem = props => (
  <div className="eventBody d-flex flex-row mt-4 ">
    <div>
      <img src={props.event.image} alt="shape" width="50" height="50" className="rounded"/>
    </div>  
    <div className="ml-3 w-100">
      <p><b>{props.event.title}</b></p>
      <div className="d-flex flex-columns justify-content-between align-items-center">
        { props.event.external_link && props.event.external_link.length ? 
          <a href={getValidUrl(props.event.external_link)} target="blank">
            <Button variant="link" 
              className={`sme-btn-dark rounded eventButton`} style={{textDecoration: 'none'}} > 
        Attend
            </Button>
          </a>
          : null }
       
        <span className="eventTime ">{smeFormatDate(props.event.start_date ).date} </span>
      </div>
    </div>  
  </div>
);

const UpcomingEvents = props =>{
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;
  useEffect(() => {
    if (!requested) {
      dispatch({
        type: SET_PAGE_SECTION_TITLE, setPageTitle: {
          title: `Calendar`,
          hasHr: false
        }
      });
      dispatch(loadEvents());
      dispatch(loadUpcomingEvents({limit: 3, filter: 'upcoming'}));
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: false, location: '/' } });
      setRequested(true);
    }
  }, [requested, dispatch]);

  

  const eventContents = (events) => {
    if(events.upcomingEvents.events.length){
      return events.upcomingEvents.events.map((event, index) => (
        <EventItem event={event} index={index} key={index} />
      ));
    }else{
      return (<NoEventView/>);
    }
    
  };
  return(<Card className="mt-4 rounded border-0">
         
    <Card.Body>
      <p className="eventHeader">
        Upcoming Events
      </p>
      {
        !props.event.upcomingEvents.loading ?  eventContents(props.event) : null
      }
      <Link to="/calendar"  style={{textDecoration: 'none'}}>
        <Button variant="link" 
          className={`sme-btn-primary rounded seeAllMain btn-block mt-4 p-3 no-border`} 
          style={{textDecoration: 'none'}} > 
          View Events
        </Button>
      </Link>
    </Card.Body>
  </Card>);
};

const mapStateToProps = state => {
  return {
    event: state.eventState
  };
};
export default connect(mapStateToProps)(UpcomingEvents);