import React, {useState} from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import './ProfileEdit.scss';
import { connect } from "react-redux";
import { UPDATE_USER_BUSINESS } from '../../store/actions/userActions';
import { SHOW_SNACKBAR } from '../../store/actions/coreActions';
import { industryList, positionList, nigerianStates } from '../../constants/general';
import Loader from '../Loader/Loader';
import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import {disableForm} from '../../helpers/utility';
const BusinessCardEdit = props => { 
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const toggleForm = e => {
    const text = !disabled ? 'Edit Business Profile' : 'Cancel';
    e.target.innerText = text;
    setDisabled(!disabled);
    disableForm('form.businessProfile  .form-control', disabled);
  };
  const saveBusinessInformation = (event )=>{
    event.preventDefault();
    if(isLoading){
      return;
    }
    const data = new FormData(event.target);
    setIsLoading(true);
    http.put(apiRoutes.USER_EDIT_BUSINESS, data).then(response => {
      setIsLoading(false);
      setDisabled(!disabled);
      props.dispatch({
        type: UPDATE_USER_BUSINESS,
        payload: {business_information: response.data.data}
      });
      props.dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: response.data.message}
      } });
    });

  };
  // console.log(props.student.business_information.position)
  return (<Card className="border-0 mt-4">
    <Card.Body>
      <Card.Title className="profile mb-4 d-flex flex-columns justify-content-between align-items-center">
        <span>Business Profile</span>
        <span><Button variant="link" style={{textDecoration: 'none', color:'#111'}} className="no-lift sme-btn-grey" onClick={toggleForm}> Edit Business Profile </Button></span>
      </Card.Title>
      <Form onSubmit={saveBusinessInformation} className="businessProfile">
        <Form.Group controlId="formBName">
          <Form.Label>Business Name</Form.Label>
          <Form.Control disabled={disabled} name="business_name" type="text" placeholder="Enter Business name" defaultValue={props.student && props.student.business_information ? props.student.business_information.business_name : ''}/>
        </Form.Group>
        <Form.Group controlId="formBDescription">
          <Form.Label>Business Description (Required)</Form.Label>
          <textarea disabled={disabled} name="business_description" className="form-control" cols="20" rows="5" placeholder="Enter Business description" defaultValue={props.student && props.student.business_information ? props.student.business_information.business_description : ''}></textarea>
        </Form.Group>
        <Form.Group controlId="formBAddress">
          <Form.Label>Business Address (Required)</Form.Label>
          <textarea disabled={disabled} name="address" className="form-control" cols="20" rows="5" placeholder="Enter Business Address description" defaultValue={props.student && props.student.business_information ? props.student.business_information.address : ''}></textarea>
        </Form.Group>
        <Form.Group controlId="formBIndustry">
          <Form.Label>Industry (Optional)</Form.Label>
          {
            disabled ? 
              <div className="profileLabel rounded px-3">{(props.student && props.student.business_information) ? props.student.business_information.industry: ''} </div>
              :
              <Form.Control disabled={disabled} defaultValue={props.student && props.student.business_information ? `${props.student.business_information.industry}` : ''} as="select" name="industry" >
                <option value="">SELECT INDUSTRY</option>
                {
                  industryList.map((industry, index) => {
                
                    return <option key={index} >{industry}</option>;
                  })
                }
              
              </Form.Control>
          }
        </Form.Group>
        <Form.Group controlId="position">
          <Form.Label>Position (Optional)</Form.Label>
          {
            disabled ? 
              <div className="profileLabel rounded px-3">{(props.student && props.student.business_information) ? props.student.business_information.position: ''} </div>
              : 
              <Form.Control disabled={disabled} name="position" as="select" defaultValue={props.student && props.student.business_information ? `${props.student.business_information.position}` : ''}>
                <option>Choose...</option>
                {
                  positionList.map((position, index) => {
                    return <option key={index} >{position}</option>;
                  })
                }
              </Form.Control>
          }
          
         
        </Form.Group>
        <Form.Group controlId="state">
          <Form.Label>State </Form.Label>
          {
            disabled ? 
              <div className="profileLabel rounded px-3">{(props.student && props.student.business_information) ? props.student.business_information.state: ''} </div>
              :
              <Form.Control size="sm" disabled={disabled} as="select" name="state" defaultValue={props.student && props.student.business_information ? `${props.student.business_information.state}` : ''} required>
                <option>Choose...</option>
                {
                  nigerianStates.map((state, index) => {
                    return (<option key={index}>{state}</option>);
                  })
                }
              </Form.Control>
          }
        </Form.Group>
        <Form.Group controlId="country">
          <Form.Label>Country </Form.Label>
          {
            disabled ? 
              <div className="profileLabel rounded px-3">{(props.student && props.student.business_information) ? props.student.business_information.country: ''} </div>
              :
              <Form.Control disabled={disabled} name="country" as="select" defaultValue={props.student && props.student.business_information ? `${props.student.business_information.country}` : ''}>
                <option>Choose...</option>
                <option>Nigeria</option>
              </Form.Control>
          }
        </Form.Group>
         
        <Form.Group controlId="registration_date">
          <Form.Label>Registration Date</Form.Label>
          <Form.Control disabled={disabled} name="registration_date" type="text" placeholder="Enter Business registration date" defaultValue={props.student && props.student.business_information ? props.student.business_information.registration_date : ''}/>
        </Form.Group>
        <Form.Group controlId="rc_number">
          <Form.Label>RC NUMBER <span className="text-muted">(RC-090909)</span></Form.Label>
          <Form.Control disabled={disabled} name="rc_number" type="text" placeholder="Enter Business registration number" defaultValue={props.student && props.student.business_information ? props.student.business_information.rc_number : ''}/>
        </Form.Group>
        <Button disabled={disabled} className="no-border sme-btn-primary px-4  py-2 mt-5 d-flex align-items-center flex-columns" type="submit">
                      Update Business Profile
          <span className="ml-3">{isLoading ? <Loader scale="0.3"/> : '' }</span>
        </Button>
      </Form>
    </Card.Body>
  </Card>
  ) ;
};

export default connect()(BusinessCardEdit);