import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { updateQueryParams } from "../../helpers/url";
import { getListFromResponse } from "../../helpers/utility";

export const LOAD_EVENTS = "SET/EVENTS";
export const LOAD_UPCOMING_EVENTS = "SET/UPCOMINGEVENTS";
export const UPDATE_EVENTS = "UPDATE/UPCOMINGEVENTS";
export const VIEW_AN_EVENT = 'GET/EVENT';
export const loadEvents = () => {
  return function(dispatch) {
    return http.get(updateQueryParams(apiRoutes.LOAD_EVENTS, { limit: 10 }) )
      .then(response => {
        dispatch({
          type: LOAD_EVENTS, 
          payload: {events : getListFromResponse(response, 'events'), loading: false}
        });
      });
  };
};
export const loadUpcomingEvents = (params) => {
  return function(dispatch) {
    return http.get(updateQueryParams(apiRoutes.LOAD_EVENTS, {...params}) )
      .then(response => {
        dispatch({
          type: LOAD_UPCOMING_EVENTS, 
          payload: {upcomingEvents:  getListFromResponse(response, 'events'), loading: false}
        });
      });
  };
};




