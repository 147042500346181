import React, {useCallback, useEffect, useState} from 'react';
import {Form, Card} from 'react-bootstrap';
import './Subscribe.scss';
import http from "../../../helpers/http";
import apiRoutes from "../../../constants/apiRoutes";
import PageLoader from "../../PageLoader/PageLoader";
import Loader from "../../../components/Loader/Loader";
import {replaceDynamics, updateQueryParams} from "../../../helpers/url";
import cookie from "../../../helpers/cookie";
import {useParams, useHistory} from "react-router-dom";
import NotFoundPage from "../NotFound/NotFound";
import {hasSubscribedToCourse} from "../../../helpers/utility";

const PaymentSection = ({course, voucher, formatAmount}) => {
  const [amount, setAmount] = useState(parseInt(course.cost || '0', 10));
  const [voucherMessage, setVoucherMessage] = useState('');
  
  useEffect(() => {
    if (voucher && voucher.value) {
      const voucherValue = parseInt(voucher.value, 10);
      let discount = 0;
  
      switch (voucher.type) {
        case 'PERCENTAGE':
          discount = parseInt(course.cost || '0', 10) * voucherValue * 0.01;
          setVoucherMessage(`${voucherValue}% off`);
          break;
        case 'BUY_AND_GET_X_FREE':
          break;
        default:
          discount = voucherValue;
          setVoucherMessage(`${formatAmount(voucherValue)} off`);
      }
      setAmount( Math.max(parseInt(course.cost || '0', 10) - discount, 0));
    }
    // eslint-disable-next-line
  }, [voucher, course]);
  
  return (
    <Card className="bg-white w-100 p-3">
      <p>Total</p>
      <h2>{formatAmount(amount)}</h2>
      {voucherMessage ?
        <p><del>{formatAmount(course.cost || '0')}</del> <br/> <span>{voucherMessage}</span> </p>: null
      }
      <a  className={`no-border btn lift sme-btn-primary`}
          href={updateQueryParams(replaceDynamics(
            `${process.env.REACT_APP_BASE_API_URI}${apiRoutes.SUBSCRIBE}`, {paymentMethod: 'paystack'}
          ), {voucher: (voucher || {}).id, regId: course.id, regType: 'course', accessToken: cookie.get('refreshToken')})}>
          {course.cost ? 'Make Payment' : 'Subscribe Now'}
      </a>
    </Card>
  )
};

const Voucher = ({setValidVoucher}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [voucher, setVoucher] = useState('');
  const [voucherError, setVoucherError] = useState('');
  
  const verifyVoucher = useCallback((v) => {
    setIsLoading(true);
    http.get(replaceDynamics(apiRoutes.VERIFY_VOUCHER, {id: v})).then((res) => {
      if(res.data && res.data.data) {
        setValidVoucher(res.data.data);
      }
    }).catch(() => {
      // setVoucherError(error.response.data.data);
      setVoucherError('Invalid discount code');
    }).finally(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  
  const onChange = (e) => {
    setVoucherError('');
    setVoucher(e.target.value)
  };
  
  const onSubmit = () => {
    if(!voucher) {
      setVoucherError('Please enter discount code!');
      return;
    }
    verifyVoucher(voucher);
  };
  
  return (
    <>
      <p>Do you have a discount code?</p>
      <Form.Group className="col-md-8 px-0 d-flex flex-row align-items-stretch">
        <Form.Control id="voucher" name="voucher" className="mr-2" type="text" placeholder="" value={voucher} onChange={onChange} />
        <button onClick={onSubmit} className="btn sme-btn-secondary lift d-flex align-items-center no-border outline-none" >
          <span>{isLoading ? <Loader scale="0.3" /> : '' }</span>
          Apply
        </button>
      </Form.Group>
      <p className="error text-danger">{voucherError}</p>
    </>
  )
};

const Subscribe = () => {
  let { courseId } = useParams();
  let history = useHistory();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verifiedVoucher, setVerifiedVoucher] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    http.get(replaceDynamics(apiRoutes.COURSE_DETAIL_WITH_SUB, {id: courseId})).then((res) => {
      setCourse(res.data.data)
    }).catch().finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    const hasSubscribed = hasSubscribedToCourse(course);
    if(hasSubscribed) {
      history.push(`/classroom/${course.subscription.id}`);
    }
    if(course && course.is_free)  {
      history.push(`/courses/${course.id}`);
    }
    // eslint-disable-next-line
  }, [course]);
  
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount)
  };
  
  return loading ?
    <PageLoader/> :
    course ? (
      <div className="Subscribe col-12 px-0 d-flex flex-column flex-sm-row align-items-sm-start">
        <section className="col-12 px-0 col-sm-8 mb-4 pt-3 mb-sm-0">
          <div className="bg-white col-12 px-0 d-flex flex-column flex-md-row align-items-md-stretch py-3">
            <img src={course.image} className="col-6 col-md-3 course-img" alt=""/>
            <div className="mb-2 col-12 col-md-6">
              <h5>{course.title}</h5>
              <span className="font-weight-light">{course.authors && course.authors.length ? course.authors[0].name : null}</span>
            </div>
            <h5 className="col-12 col-md-3 text-md-right">{formatAmount(course.cost)}</h5>
          </div>
    
          <div className="py-3">
            <Voucher setValidVoucher={setVerifiedVoucher}/>
          </div>
        </section>
        <section className="col-12 px-0 pl-sm-3 col-sm-4 py-sm-3 mb-4 mb-sm-0">
          <PaymentSection course={course}
                          voucher={verifiedVoucher}
                          formatAmount={formatAmount}/>
        </section>
      </div>
    ) :
      <NotFoundPage title="Course not found"
                    subtitle="Oops!, it seems the course you are looking for does not exist or has been removed"
                    goto="/courses"
                    cta="See all courses"/>
};


export default Subscribe;
