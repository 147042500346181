import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import './ProgressRing.scss';

const ProgressBar = props => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  const { 
    size, 
    progress, 
    strokeWidth, 
    circleOneStroke, 
    circleTwoStroke,
    count,
    title
  } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out;';
  }, [setOffset, circumference, progress, offset]);

  return (
    <>
      <svg
        className="svg"
        width={size}
        height={190}
      >
          
        
        
        <circle
          className="svg-circle-bg"
          stroke={circleOneStroke}
          cx={center}
          cy={55}
          r={50}
          strokeWidth={strokeWidth}
        />
       
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={circleTwoStroke}
          cx={center}
          cy={55}
          r={50}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={isNaN(offset) ? 0 : offset}
        />
        { title.toLowerCase() !== 'completed' ? 
          <svg x="60" y="50" width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="courseStatsPending" d="M26 15.88C26 14.8882 25.3613 14.0507 24.4766 13.7351V5.84405L26 5.21594L13 0L0 5.21594L13 10.4319L22.9531 6.45576V13.7351C22.0684 14.0507 21.4297 14.8882 21.4297 15.88C21.4297 16.788 21.9659 17.5674 22.7348 17.9357L21.4691 21.7327L22.9137 22.2147L23.7148 19.8113L24.516 22.2147L25.9605 21.7327L24.6949 17.9357C25.4638 17.5674 26 16.788 26 15.88Z" fill="#0062FF"/>
            <path id="courseStatsPending" d="M13 12.0736L6.14453 9.32089V11.3097C6.14453 13.481 9.09172 15.1183 13 15.1183C16.9083 15.1183 19.8555 13.481 19.8555 11.3097V9.32089L13 12.0736Z" fill="#0062FF"/>
          </svg>
          :
          <svg x="65" y="50" width="26" height="29" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="courseStatsPassed" fillRule="evenodd" clipRule="evenodd" d="M9.36303 0.713043H13.9043C15.1583 0.713043 16.1749 1.72964 16.1749 2.98367V10.9309H20.7161C21.9702 10.9309 22.9868 11.9475 22.9868 13.2015V22.284C22.9868 22.911 22.4785 23.4193 21.8514 23.4193H15.0396C15.0373 23.4193 15.0351 23.4193 15.0329 23.4193H8.23189C8.23049 23.4193 8.22908 23.4193 8.22768 23.4193H1.4158C0.788784 23.4193 0.280487 22.911 0.280487 22.284V8.09259C0.280487 6.83856 1.29708 5.82196 2.55111 5.82196H7.09237C7.09238 5.82196 7.09239 5.82196 7.0924 5.82196V2.98367C7.0924 1.72964 8.109 0.713043 9.36303 0.713043ZM13.9043 13.1912C13.9043 13.1946 13.9043 13.1981 13.9043 13.2015V21.1487H9.36302V2.98373H13.9043V13.1912ZM16.1749 21.1488H20.7162V13.2016H16.1749L16.1749 21.1488ZM7.0924 8.09268V21.1488H2.55114V8.09268H7.0924Z" fill="#14A277"/>
          </svg>

        }
        <text 
          x={70} 
          y="145"
          className="coursecount">
          {count}
        </text>
        <text 
          x={35} 
          y="180"
          className="courseCountTitle">
          {title}
        </text>
      </svg>
      {/* {props.children} */}
    </>
  );
};

ProgressBar.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  circleOneStroke: PropTypes.string.isRequired,
  circleTwoStroke: PropTypes.string.isRequired
};

export default ProgressBar;