import React from "react";
import snackbarStyle from "./Snackbar.module.scss";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const ShowSnackbar = props =>( <Snackbar
  anchorOrigin={{
    vertical:  props.vertical || "top",
    horizontal: props.horizontal|| "right"
  }}
  open={props.openSnackBar}
  autoHideDuration={ 2000}
  onClose={props.handleClose}
  aria-describedby="client-snackbar"
  message={
    <span id="client-snackbar">
      {props.message}
    </span>
  }
  action={[
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      className={snackbarStyle.noOutline}
      onClick={props.handleClose}
    >
      <Close/>
    </IconButton>
  ]}
/>);

export default ShowSnackbar;