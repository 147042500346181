import React, { useState, useEffect, Fragment } from 'react';
import {  Row } from 'react-bootstrap';
import './UpcomingEvents.scss';
import '../../assets/scss/custom-buttons.scss';

import { connect } from 'react-redux';
import { SET_PAGE_BACK_BUTTON,
  SET_PAGE_SECTION_TITLE,
  setCurrentMenu } from '../../store/actions/coreActions';
import Shimmer from '../Shimmer/shimmerArticle';
import { loadUpcomingEvents } from '../../store/actions/eventsActions';

import EventCard from './EventCard';
import NoEventView from './noEvents';
import Pagination from '../Pagination/Pagination';

const AllUpcomingEvents = props => {
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;
  useEffect(() => {
    if (!requested) {
      dispatch({
        type: SET_PAGE_SECTION_TITLE, setPageTitle: {
          title: `Calendar`,
          hasHr: false
        }
      });
      dispatch(setCurrentMenu('Calendar'));
      dispatch(loadUpcomingEvents({limit: 5, filter: 'upcoming'}));
      document.querySelector('#header #pageTitle .page-title').innerText = 'Back to Upcoming Events';
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: true, location: '/calendar' } });
      setRequested(true);
    }
  }, [requested, dispatch]);
  
   
  
    
  
  const eventContents = (events) => {
    
    if(events.upcomingEvents.events.length){
      return events.upcomingEvents.events.map((event, index) => (
        <EventCard event={event} index={index} key={index} />
      ));
    }else{
      return (<NoEventView/>);
    }
      
  };
  
  return (
    <Fragment>
      <Row className=" py-4 calendar">
  
  
        <div className="col-md-12 eventHeader d-flex justify-content-between">
          <div>
            All Events
            <p className="mt-2"><small>Events you might be interested in.</small></p>
          </div>
          <div>
            
          </div>
        </div>
        {
          !props.event.upcomingEvents.loading ?  
            eventContents(props.event) :
            <Shimmer maxCards="6" />
        }
        <div className="col-md-12 ">
          <Pagination list={props.event.upcomingEvents} updateWho="event" title="See more events"/>
        </div>
      </Row>
      
    </Fragment>);
};
const mapStateToProps = state => {
  return {
    event: state.eventState
  };
};
export default connect(mapStateToProps)(AllUpcomingEvents);