import React, {useState, Fragment, useRef, useCallback} from 'react';
import './Notification.scss';
import moment from 'moment';
import { NOTIFICATION_TYPES } from '../../constants/general';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useOutsideClick from "../../hooks/outsideClick";

const NotificationComponent = () => {
  const [hasNotification, setHasNotification] = useState(false);
  const notifications = useSelector(state => state.coreState.notifications);
  const [show, setShow] = useState(false);
  const notificationRef = useRef(null);
  // .dropdown-content-active
  const seeOptions = () => {
    setHasNotification(false);
    setShow(prevState => !prevState);
  };

  useOutsideClick(notificationRef, useCallback(() => {
    if(show) setShow(false);
  }, [show]));

  const notificationText = (notificationItem, index) => {
    const creator = (notificationItem || {}).creator;
    switch (notificationItem.type) {
      case NOTIFICATION_TYPES.DISCUSSION_NOTIFICATION:

        return (<div className="notification-item bg-white d-flex px-3  py-3 align-items-center justify-content-between" key={index}>
          { creator ? <img src={notificationItem.creator.image}
            className="rounded-circle mr-3" width="40" height="40" alt="avatar" /> : null}
          <div className="py-1 notificationDetails flex-grow-1">
            <p className="text-left mb-0">
              {
                creator ?
                  `${creator.first_name} ${creator.last_name} ${notificationItem.action} your post`
                  : `A user ${notificationItem.action} your post`
              }
            </p>
            <p className="text-left small  text-muted time mb-0">{moment(notificationItem.created_at).fromNow()}</p>
          </div>

        </div>);
      case NOTIFICATION_TYPES.PAYMENT_NOTIFICATION:
        return <div className=" notification-item  bg-white d-flex px-3  py-3 align-items-center justify-content-between" key={index}>
          <div className="py-1 notificationDetails flex-grow-1">
            <p className=" mb-0">
              {
                notificationItem.action
              }
            </p>
            <p className="text-left small text-muted time mb-0">{moment(notificationItem.created_at).fromNow()}</p>
          </div>

        </div>;
      default:
        break;
    }
  };
  const parseNotification = () => notifications.map((notificationItem, index) => {
    return (notificationText(notificationItem, index));
  });
  return (
    <Fragment>
      <div className="dropdown  " ref={notificationRef}>
        <i onClick={() => seeOptions()}
          className={`fa fa-bell-o pr-3 notification dropbtn ${hasNotification ? 'active' : ''}`}>
        </i>
        <div className=" d-none d-lg-block">
          <div className={`dc dropdown-content bg-white mt-3 ${show ? 'dropdown-content-active' : ''}`}>
            <Link className="text-dark" to={{
              pathname: '/profile'
            }}>
              <div style={{ background: '#ffe000' }} className=" notification-item  d-flex px-3  py-3 align-items-center justify-content-between" >
                <div className="py-1 callOut flex-grow-1">
                  <div className=" mb-0" style={{ fontSize: '1rem', lineHeight: '24px' }}>
                    <i className="fa fa-cog mr-3 text-dark rounded-circle notifictionIcon" style={{ fontSize: '1.4rem' }}></i>
                Important: Update your profile
                </div>

                </div>

              </div>
            </Link>
            <div className="inner">
              {
                notifications.length ? parseNotification() : <p className="text-center p-4">No Notification</p>
              }
            </div>

          </div>

        </div>
      </div>
      <div className="d-block d-lg-none  ">
        <div className={`dcs dropdown-content-mobile bg-white mt-3 ${show ? 'dropdown-content-active' : ''}`}>
          <h3 className="p-3 title">Notifications</h3>
          <Link className="text-dark" to={{
            pathname: '/profile'
          }}>
            <div style={{ background: '#ffe000' }} className=" notification-item  d-flex px-3  py-3 align-items-center justify-content-between" >
              <div className="py-1 callOut flex-grow-1">
                <div className=" mb-0" style={{ fontSize: '1rem', lineHeight: '24px' }}>
                  <i className="fa fa-cog mr-3 text-dark rounded-circle notifictionIcon" style={{ fontSize: '1.4rem' }}></i>
         Important: Update your profile
                </div>

              </div>

            </div>
          </Link>
          <div className="inner h-100">
            {
              notifications.length ? parseNotification() : <p className="text-center p-4">No Notification</p>
            }
          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default NotificationComponent;
