import React, {  useState, useEffect} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {LazyImage} from '../CourseItem/LazyLoadImage';
import LikeButton from '../DicussionCard/LikeButton';
import ReplyButton from '../DicussionCard/ReplyButton';
import ReplySection from '../DicussionCard/Comment';
import moment from 'moment';
import { getRandomColor } from '../../helpers/utility';
import avatar from '../../assets/img/header/avatar.png';

const SingleDiscussion = props =>{
  const { discussion, index, addHeader, user } = props;
  // const [showReplies, setShowReplies] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(true);

  useEffect(() =>{
    if (!discussion) {
      // setShowReplies(false);
    }
  }, [discussion]);

  const {
    id,
    PostReactions,
    User: {first_name, last_name, image},
    created_at,
    likes,
    content,
    discussion: { title },
    comment
  } = discussion || {User: {}};
  return (
    <Col md={12} sm={12}
      key={`discussion-col-${index}`}
      id={`discussion-col-${index}`}
      className={addHeader ? '' : ''}>
      <Card
        className={`border-0 rounded mb-3`}
        key={`discussion-item-${index}`}>
        <Card.Header
          className="discussion-card-header d-none d-lg-block">
          <Row>
            <Col md={7} >
              <div className="d-flex align-items-center ">
                <LazyImage
                  width="24"
                  height="24"
                  src={image || avatar}
                  alt={first_name || ''}
                  className={'avatar mr-4'}/>
                <b>
                  {first_name || ''} {last_name || ''}
                </b>
              </div>
            </Col>
            { title.length ?
              <Col lg={5}  className="rounded py-1 "
                style={{...getRandomColor()}}>
                <div className="categories h-100 mr-1 rounded"> {title} </div>
              </Col> : <Col md={3}></Col>
            }
          </Row>
        </Card.Header>
        <Card.Header className="discussion-card-header d-block d-lg-none">
          <Row>
            <Col md={8} className="w-50">

              <div className="d-flex align-items-center ">
                <LazyImage
                  width="24"
                  height="24"
                  src={image || avatar}
                  alt={first_name || ''}
                  className={'avatar mr-4'}/>
                <div>
                  <b>
                    {first_name || ''} {last_name || ''}
                  </b>
                  <div className="d-flex align-items-center justify-content-start h-100 discussion-time">
                    {`${moment(new Date(discussion.created_at)).fromNow() }`}
                  </div>
                </div>
              </div>
            </Col>

            { title.length ?
              <Col md={4}  className="rounded d-none d-lg-block"
                style={{...getRandomColor()}}>
                <div className="categories h-100 mr-1 p-1"> {title} </div>
              </Col> : <Col md={3}></Col>
            }
          </Row>
        </Card.Header>
        <Card.Body>
          <div className={`mt-3 mb-2 discussion-card pl-3 pr-3`}>
            {content}
          </div>

        </Card.Body>

        <ReplySection
          comments={comment}
          key={index*3}
          discussion={discussion}
          show={showCommentSection}/>

        <Card.Footer className="discussion-card-footer">
          <Row>
            <Col md={8} className="d-flex align-items-center justify-content-start pl-3 pr-3">
              {/* like button */}
              <LikeButton
                id={`like-button-${index}`}
                postId={id}
                postReaction={PostReactions}
                likes={likes || 0}
                user={user}
              />

              {/* Reply button */}
              <ReplyButton
                imagePath={require('../../assets/icons/community-cards/comment.png')}
                className="commentBtn"
                onClick={()=> setShowCommentSection(showCommentSection === true ? false : true)}>
                  Reply
              </ReplyButton>

            </Col>
            <Col lg={4} className="d-none d-lg-block   ">
              <div
                className="d-flex align-items-center
              justify-content-start h-100 discussion-time ">
                {`${moment(created_at).fromNow()}`}
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  ) ;
};

export default SingleDiscussion;
