import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import './Pricing.scss';
import { paystackPaymentLink, defaultPriceContents } from '../../../constants/general';
import {
  attachAccessTokenToUrlQueryParams,
  hardRedirect,
  updateQueryParams
} from '../../../helpers/url';
import {
  setCurrentMenu, setSectionTitle
} from "../../../store/actions/coreActions";
import utilityHelper from "../../../helpers/utility";
import PageTitle from '../../../components/PageTitle/PageTitle';

const Pricing = props => {
  const [requested, setRequested] = useState(false);
  const {dispatch} = props;
  useEffect(()=>{
        
    if (!requested) {
      dispatch(setCurrentMenu('courses'));
      dispatch(setSectionTitle({
        title: 'Pricing Plan ',
        hasHr: false
      }));
      setRequested(true);
    }
  }, [requested, dispatch]);
  const onEnRollNowClicked = (plan) => {
      
    const { location } = window;
    const redirectUrl = `${(location || {}).protocol}//${(location || {}).host}/payment-success`;
    let paymentUrl = updateQueryParams(paystackPaymentLink,
      { regId: 'all-courses', redirectUrl, plan, regType: 'course' });

    if (!utilityHelper.isAuthenticated()) {
      const authUrl = `/auth/sign-up?redirect=${encodeURIComponent(paymentUrl)}`;
      this.props.history.push(updateQueryParams(authUrl, { requireIdentity: 1 }));
    } else {
      paymentUrl = attachAccessTokenToUrlQueryParams(paymentUrl);
      hardRedirect(paymentUrl);
    }
  };
  
  return (
    <div className="pricing">
      {/* <div className={`col-12 p-0 pb-5   min-content-height`}> */}
      <div className={`col-12  p-0`}>
        <div className="" >
          <div className="d-none d-lg-block">
            <PageTitle />
          </div>
            
        </div>
      </div>
      {/* </div> */}
      <section className="sec-1 w-100 d-flex flex-column justify-content-center align-items-center pt-5">
        <article className="container">
          <h1 className=" text-center mb-5 m-auto" style={{maxWidth: '30rem'}}> Gain access to our top professionals</h1>
          <p className="text-center mt-4 mb-0"> 
          Get access to the professionals at TBWA Concept Unit, you will get tailor-made business <br/>
          development support from a world className marketing communication agency. We will help you <br/> 
          become competitive and  help you manage your business in a more sustainable manner.</p>
        </article>
      </section>
      <section className="sec-2 w-100 px-0 py-5  mb-4">
        <div className="row " style={{'maxWidth': '70rem'}}>
          
          {
            defaultPriceContents.map((priceContent, i)=>{
              return (<div key={`div-${i}`} className="col-12 col-md-4 mx-auto mb-3">
                <article key={`article-${i}`} className={`lift ${priceContent.cardClass} container d-flex flex-column justify-content-center align-items-start p-5`}>
                  <p className={`${priceContent.textColor} `}>{priceContent.type}</p>
                  <h2 className={`${priceContent.textColor} no-border`}>₦{priceContent.price}</h2>
                  <ul className="px-0 list-unstyled">
                    {
                      priceContent.items.map((li, j) => 
                        (<li key={j} className="d-flex">
                          <img className="mr-4" src={li.icon} style={{height:'18px'}} alt="li-img"/>
                          <p className={`${li.class} size-14-px`}>{li.text}</p>
                        </li>)
                      )
                    }
                  </ul>
                  <p className="font-italic size-14-px">Third party cost not included *</p>
                  
                  <Button
                    variant="link"
                    style={{textDecoration: 'none'}}
                    className={`${priceContent.btnClass}  no-border`}
                    onClick={() => onEnRollNowClicked(priceContent.plan)}>
                    Pay Now
                  </Button>
                  {/* <a className={priceContent.btnClass} href="/auth/sign-in?plan=basic" target="_self">Pay Now</a> */}
                  <button className="d-none align-self-center mt-3 makeEnquiry size-14-px no-border" type="button" id="makeEnquiry">Make enquiry</button>
                </article>
              </div>);
            })
          }
            
            
          
        </div>
      </section> 
    </div>
  );
};

export default connect(null, null)(Pricing);