import * as actionType from "../actions/walkthroughAction";
const initialState = {
  menuToTrigger: '',
  resetMenu: false, // this key is used to skip tour or activate the dashboard menu
  centerModal: true,
  showModal: true
};
const walkthroughReducer = (state = initialState, action) => {
  
  switch(action.type){
    case actionType.TRIGGER_DASHBOARD_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_DASHBOARD_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_COURSE_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_COURSE_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_EVENT_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_EVENT_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_CALENDAR_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_CALENDAR_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_COMMUNITY_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_COMMUNITY_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_PROFILE_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_PROFILE_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_SETTINGS_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_SETTINGS_MENU_ITEM;
      return  { ...state };
    case actionType.TRIGGER_LOGOUT_MENU_ITEM:
      state.menuToTrigger = actionType.TRIGGER_LOGOUT_MENU_ITEM;
      return  { ...state };
    case actionType.POSTION_MODAL_TOP_LEFT:
      state.centerModal = false;
      return { ...state };
    case actionType.POSTION_MODAL_CENTER:
      state.centerModal = true;
      return { ...state };
    case actionType.RESET_WALKTHROUGH:
      state.resetMenu = true;
      state.showModal = false;
      return { ...state };
    case actionType.RESET_SIDEBAR:
      state.resetMenu = action.message;
      state.menuToTrigger = actionType.TRIGGER_DASHBOARD_MENU_ITEM;
      return { ...state };
    case actionType.SHOW_MODAL:
      state.showModal = false;
      state.resetMenu = true;
      state.menuToTrigger = actionType.TRIGGER_DASHBOARD_MENU_ITEM;
      return { ...state };
    default:
      return state;
  }
};
export default walkthroughReducer;