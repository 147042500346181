import Icon from './svgIcon';
import React from 'react';
import { getRandomColor } from '../../helpers/utility';
import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { LOAD_DISCUSSION } from '../../store/actions/discussionActions';
import { connect } from 'react-redux';
import { getListFromResponse } from '../../helpers/utility';
import { updateQueryParams } from '../../helpers/url';


const DiscussionTopics = props => {
  // console.log(props);
  const { topic, dispatch } = props;
  const color = getRandomColor();
  const toggleClick = (courseID) => {
    http.get(
      updateQueryParams(apiRoutes.LOAD_COURSE_DISCUSSIONS.replace('@id', courseID),
        {limit: 10}))
      .then(response => {
        dispatch({
          type: LOAD_DISCUSSION, 
          payload: {discussions:  getListFromResponse(response, 'discussions'), loading: false}
        });
        
      });
  };
  return (
    <div className="d-flex justify-content-between align-items-center mt-4">
      <div style={{  marginBottom: '0px' }} className="d-flex justify-content-between align-items-center">
        <Icon fillColor={color.color} style={{ height: '50px' }} />
        <span onClick={()=>toggleClick((topic || '').course_id)} 
          style={{ marginLeft:'1rem', fontSize: '0.75rem', fontWeight: '500', cursor:'pointer' }}>
          {(topic || '').title || ''}</span>
      </div>
      <span className="categories h-100 mr-1 p-2 rounded"
        style={{ ...color, fontSize: '0.75rem', fontWeight: '500' }}> 
        {(topic || '').cnt} </span>
    </div>
  );
};

export default connect()(DiscussionTopics);