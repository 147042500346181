import React from 'react';

const ReplyButton = ({children, onClick,imagePath, ...props }) =>{
  return (
    <button {...props} onClick={onClick}>
      <img src={(imagePath)} className={`mr-2`} alt="comment"/>
      {children}
    </button>
  );
};

export default ReplyButton;