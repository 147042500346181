import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import Loader from  '../Loader/Loader.js';
import { useSelector, connect } from 'react-redux';
import avatar from '../../assets/img/header/avatar.png';


import http from '../../helpers/http';
import apiRoutes from '../../constants/apiRoutes';
import {
  SHOW_SNACKBAR
} from "../../store/actions/coreActions";
import {
  loadingDiscussion, loadCourseDiscussions
} from "../../store/actions/discussionActions";
const StartDiscussionModal = ({ ...props}) => {
  const userData = useSelector(state => state.userState.user);
  const [loading, setLoading] = useState(false);
  const { dispatch, topics } = props;
  const generateOption = ()=>{
    let options = [];
    if(topics && topics.length){
      topics.forEach((topic, index)=>{
        options.push(<option key={index} value={topic.module_topic.course_id}>{topic.title}</option>);
      });
    } 
    return options; 
  };
  const postDiscussion = ()=> {
    const discussionText = document.getElementById('discussionText').value;
    const discussionCourse = document.getElementById('discussionCategory');
    if(discussionText.length > 0 && 
      discussionCourse.options[discussionCourse.selectedIndex].value.length > 0){
      setLoading(true);
      http.post(apiRoutes.ADD_COURSE_DISCUSSION, 
        {
          course_id: discussionCourse.options[discussionCourse.selectedIndex].value,
          content: discussionText, 
          title:  discussionCourse.options[discussionCourse.selectedIndex].text
        })
        .then(response => {
          dispatch(loadingDiscussion());
          dispatch(loadCourseDiscussions(response.data.data.results[0].course_id));
          dispatch({ type: SHOW_SNACKBAR, payload: {
            config: {message: response.data.message}
          } });
          setLoading(false);
          document.getElementById('discussionText').value= '';
          document.getElementById('discussionCategory').selectedIndex = "0";
          
          
        });
    }else{
      dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: 'Discussion topic or Discussion cannot be empty'}
      } });
    }
  };
  return (
    <Card
      className={` border-0  mt-1 mb-5  discussionModal`}
    >
      <Card.Header >
        <Card.Title className={`classTitle`}>Post Something</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between">
        
          <img 
            src={userData && userData.image ? userData.image : avatar} 
            alt="useerlogo" className="rounded-circle" style={{height:'36px', width:'36px'}}/>
         
          <textarea id="discussionText" className={`discussionTextBox ml-2 mr-2`} placeholder="What's on your mind now we are here"></textarea>
          { loading ? <Loader color="#0062FF" scale={props.scale} type={props.type || "ripple"}/> : '' } 
        </div>
      </Card.Body>
      <Card.Footer >
        <div className={`startDiscussionCardFooter w-100`}>
          
          <div className="select-holder p-0 pl-3">
            <span className="w-50">Topic:</span>
            <select id="discussionCategory" className="w-50">
              <option value=''>Choose...</option>
              {generateOption()}
            </select>

          </div>
          { !loading ?  <Button className={`py-3  no-border sme-btn-primary btn-block postButton no-border`} onClick={postDiscussion}>
            Share Post
          </Button>
            : '' }
        </div>
       
      </Card.Footer>
    </Card>);
};
const mapStateToProps = state => {
  return {
    discussions: state.discussionState.discussions,
    categories: state.discussionState.categories,
    topics: state.discussionState.topics
  };
};


export default  connect(mapStateToProps, null)(StartDiscussionModal);