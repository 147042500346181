import React, { useState, Fragment } from 'react';

// import { connect } from 'react-redux';
import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes"; 
import ShowSnackbar from '../UI/Snackbar/SnackbarNoRedux';


/**
 * This button for the discussion section
 * @param {*} children the content for the button innerHTML
 * @param {Function} onClick the function for the button
 * @param {string} imagePath the path to the image icon
 * 
 */
const LikeButton = props =>{

  const {  postReaction, likes, postId } = props;

  // const ref = useRef(`like-${postId}`);
  const [ openSnackBar, setOpenSnackBar] = useState(false);
  const [ networkMessage, setNetworkMessage] = useState('');
  const [likeCount, setLikeCount] = useState(parseInt(likes, 10));
  // console.log("Helper: ", {postId ,p:( postReaction.length ?? postReaction[0].user_id === user.id)});
  const [liked, setLiked] = useState(
    postReaction.length
  );

  // check to see if this user has liked this post or not on page reload

  // postdiscussion ? postdiscussion.PostReactions && postdiscussion.PostReactions.length
  //   ? postdiscussion.PostReactions[0].user_id === ( props.user.id) 
  //   : false : false;


  const [loading, setLoading] = useState(false);
  
  const likePost =  async () =>{ 
    // console.log(document.getElementById(ref.current.id).children[1].innerText.match(/\d/)[0]);
    if(loading){
      return;
    }
    setLoading(true);
    const result = await http.put(apiRoutes.LIKE_DISCUSSION.replace('@id', postId))
      .catch( e => {
        setLikeCount(likeCount);
        setLiked(liked);
        setOpenSnackBar(true);
        setNetworkMessage(String(e));
      });
    setLiked(result.data.data.PostReactions.length);
    setLikeCount(result.data.data.likes);
    console.log({postId, likes: parseInt(result.data.data.likes, 10)});
    setLoading(false);
  };
  
  const handleClose = ()=>setOpenSnackBar(false);
  return (
    <Fragment>
      {
        <ShowSnackbar 
          vertical="bottom"
          horizontal="left" 
          handleClose={()=>handleClose()} 
          message={networkMessage} 
          openSnackBar={openSnackBar}/>
      }

      <button key={Math.random()} disabled={loading}  id={`btn-${postId}`} 
        className={`likeBtn ${liked ? 'fill-red' : ''}`} onClick={likePost}>
        <img src={require('../../assets/icons/community-cards/likes.png')} alt="like button"/>
        <span className="counter">{parseInt(likeCount, 10) || 0} </span>
        {(parseInt(likeCount, 10) || 0) > 1 ?  'Likes' : 'Like'}
      </button>
    </Fragment>
   
  );
};


export default (LikeButton);