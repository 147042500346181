import React, {useState} from 'react';
import './CustomSelect.scss';

const CustomSelect = ({options, defaultOption, onChange}) => {
  options = [{id: "", name: "All"}, ...options];
  const [selectedOption, setSelectedOption] = useState(defaultOption || options[0].id);
  const onSelectedChanged = (e) => {
    setSelectedOption(e.target.value);
    if(typeof onChange === 'function') {
      onChange(e, e.target.value);
    }
  };
  return (
    <div className="select-holder p-0 pl-3">
      <span>Filter:</span>
      <select value={selectedOption} onChange={onSelectedChanged}>
        {options.map((opt, index) => <option key={index} value={opt.id}>{opt.name}</option>)}
      </select>
    </div>
  );
};

export default CustomSelect;
