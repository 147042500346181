import React from 'react';
import { Link } from 'react-router-dom';
import './Discussion.scss';

const NoDiscussionView = () => {
 
  return (
    <div className={`col-12 d-flex pt-5 flex-column justify-content-center 
    align-items-center mx-auto`}>
      <img src={require("../../assets/img/no-activity.png")} 
        alt="" className="noCourseImg"/>
      <h1 className="noDiscussionH1 mt-3">No Activity</h1>
      <p className="mb-0 noDiscussionP mt-3 mb-5 "  >
      We couldn’t find discussion you’ve created or commented on
      </p>
      
      <Link to="/courses">
        <button className="btn sme-btn-primary font-weight-600" style={{width: '15rem'}}>
          Start a discussion
        </button>
      </Link>
    </div>
  );

};
export default NoDiscussionView;
