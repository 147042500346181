import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import './UpcomingEvents.scss';
import '../../assets/scss/custom-buttons.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SET_PAGE_BACK_BUTTON, 
  SET_PAGE_SECTION_TITLE, 
  setCurrentMenu } from '../../store/actions/coreActions';
import Shimmer from '../Shimmer/shimmerArticle';
import { loadUpcomingEvents } from '../../store/actions/eventsActions';
import CalendarWidget from '../../containers/Calendar/CalendarWidget';
import EventCard from './EventCard';
import CalendarWidgetMobile from '../../containers/Calendar/CalendarWidgetMobile';
import NoEventView from './noEvents';

const UpcomingCalendarEvents = props => {
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;
  useEffect(() => {
    if (!requested) {
      dispatch({
        type: SET_PAGE_SECTION_TITLE, setPageTitle: {
          title: `Calendar`,
          hasHr: false
        }
      });
      dispatch(setCurrentMenu('Calendar'));
      dispatch(loadUpcomingEvents({limit: 3, filter: 'upcoming'}));
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: false, location: '/' } });
      setRequested(true);
    }
  }, [requested, dispatch]);

 

  

  const eventContents = (events) => {
    if(events.upcomingEvents.events.length){
      return events.upcomingEvents.events.map((event, index) => (
        <EventCard event={event} index={index} key={index} />
      ));
    }else{
      return (<NoEventView/>);
    }
    
    
  };

  return (
    <Fragment>
      <Row className=" py-4 calendar bg-white">


        <div className="col-md-12 eventHeader d-flex justify-content-between">
          <div className="upcomingEventTitle">
          <h3>Upcoming Events</h3>
            <p className="mt-2 d-none d-lg-block"><small>Events you might be interested in.</small></p>
          </div>
          <div>
            <Link to="/calendar/all" className="allEventLink"> ALL Events</Link>
          </div>
        </div>
        {
          !props.event.upcomingEvents.loading ?  eventContents(props.event) : <Shimmer maxCards="3" />
        }
      </Row>
      <CalendarWidget events={props.event}/>
      <Row className="d-block d-lg-none">
        <Col md={12} >
          <CalendarWidgetMobile events={props.event}/>
        </Col>
      </Row>
    </Fragment>);
};
const mapStateToProps = state => {
  return {
    event: state.eventState
  };
};
export default connect(mapStateToProps)(UpcomingCalendarEvents);