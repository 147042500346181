import * as actionType from "../actions/discussionActions";

const initialState = {
  
  discussionLoading: true,
  discussionList: null,
  
  discussionWithReaction: null,
  categories: null,
  topics: null,
  discussion_topics: null
};
let updatedDiscussions;
const discussionReducer = (state = initialState, action) => {
  
  switch(action.type){
    case actionType.LOADING_DISCUSSION:
      state.discussionLoading = action.payload.loading
      return {
        ...state
      };
    case actionType.LOAD_DISCUSSION_TOPICS:
      state.discussion_topics = [ ...action.payload.discussion_topics ] ;
      return  { ...state };
    case actionType.LOAD_DISCUSSION:
      state.discussionList = {...action.payload.discussions};
      state.discussionLoading = action.payload.loading ;
      return  { ...state };
    case actionType.LOAD_TOPICS:
      state.topics = [ ...action.payload.topics ] ;
      return  { ...state };
    case actionType.PREPEND_DISCUSSION:
      // delete action.payload.discussions.discussions[0];
      
      state.discussionLoading = action.payload.loading ;
      state.discussionList.discussions
        .unshift({ ...action.payload.discussions });
      return  { 
        ...state,
        discussionList: {
          ...state.discussionList
        }
      };
    case actionType.APPEND_DISCUSSION:
      // delete action.payload.discussions.discussions[0];
        
      updatedDiscussions = state.discussionList.discussions
        .concat([ ...action.payload.discussions.discussions ]);
      return  { 
        ...state,
        discussionList: {
          ...state.discussionList,
          discussions: updatedDiscussions,
          next_page: action.payload.discussions.next_page,
          number_of_pages: action.payload.discussions.number_of_pages,
          total: action.payload.discussions.total,
          limit: action.payload.discussions.limit,
          page: action.payload.discussions.page,
          url: action.payload.discussions.url 
          
        }
      };
    case actionType.LOAD_CATEGORIES:
      state.categories = { ...action.payload.categories } ;
      return  { ...state };
    case actionType.LIKE_DISCUSSION:
      return { ...state,
        discussionWithReaction: {...action.payload}
      };
    default:
      return state;
  }
};
export default discussionReducer;