import React, { useState, Fragment } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import "./Walkthrough.scss";
import { onBoardingData } from '../../constants/onBoarding';
import { connect, useSelector } from 'react-redux';
import { triggerMenu, 
  positionWalkthrough,
  skipTour,
  hideModal, reset } from '../../store/actions/walkthroughAction';
import { setCurrentMenu } from '../../store/actions/coreActions';
import { updateUser } from '../../store/actions/userActions';
import { getOffset } from '../../helpers/utility';
import {  withRouter } from "react-router-dom";
const DashboardOnboardingScreen = (props) => {
  let [currentSlide, setCurrentSlide] = useState(0);
  const userData = useSelector(state => state.userState.user);

  /**
   * Returns the JSX with the approporiate content for slides
   * @param {*} item 
   * @returns {JXS}
   */
  const onboardingContent = item =>{
    return (
      item.icon && item.icon.length ? 
        <Fragment>
          <div className="sme-d-flex mt-3">
            {item.icon && item.icon.length ? 
              <img src={item.icon}
                className="img img-fluid walkthroughIcon mr-4 ml-4 "  alt={item.title} /> 
              : ''}
            <div> 
              <h3 className="title ">{item.title}</h3>
              <Card.Text className="carousel-small-text">
                {item.caption}
              </Card.Text>
            </div>
          </div>
        </Fragment>
        :
        <Fragment>
          <h3 className="pt-3">Welcome, <span style={{fontWeight:300}}>{userData && userData.first_name }</span></h3>
          <Card.Text className="pt-3 pb-3">
            {item.caption}
          </Card.Text>
        </Fragment>
    );
  };
  /**
   * This function updates the walkthourgh button
   * @returns {String} 
   */
  const updateButtonText = ()=>{
    if(currentSlide === 0) {
      return 'Start Tour';
    } else {
      if(currentSlide === (onBoardingData.dashboardOnboarding.length - 1)){
        return 'Finish';
      } else {
        return 'Next';
      }
    }
  };
  /**
   * This changes the current modal page
   * @param {*} event 
   */
  const updateWalk = (event)=>{
    props.resetSidebar(false);
    const cardData = event.target.dataset;
    setCurrentSlide(currentSlide + 1);
    if(event.target.innerText === 'Finish' ){
      props.setCurrentPage('dashboard');
      props.showModal();
      props.updateUserData({seenModal : true, userId: userData.id });
      props.history.push('/courses');
    }else {
      // lets get +1 of the current Card index
      let index = cardData.index;
      if(index === onBoardingData.dashboardOnboarding.length -1){
        index = parseInt((onBoardingData.dashboardOnboarding.length - 1), 10);
      } else { 
        index++;
      }
      // Dispatch actions to trigger sidbar menu, set currentPage for menu highlight and set modal position
      if(onBoardingData.dashboardOnboarding[index].activateMenu !== 'none'){
        props.triggerMenu(onBoardingData.dashboardOnboarding[index].activateMenu);
        props.setCurrentPage(onBoardingData.dashboardOnboarding[index].activateMenu);
        props.positionModal(
          onBoardingData.dashboardOnboarding[index].modalPosition
        );
        
        if(window.innerWidth > 1000){
          const activeElementOffset = getOffset(document.querySelector(`#sidebar-item-${index}`));
          document.getElementById(`card-${index}`).style.width =  '25.3%';
          document.getElementById(`card-${index}`).style.position = 'fixed';
          document.getElementById(`card-${index}`).style.top = (activeElementOffset.top+20)+"px";
        }
     
      }
    }
    
    
  };

  const decreaseWalk = (event)=>{
    const cardData = event.target.dataset;
    setCurrentSlide(currentSlide === 0 ? 0 : currentSlide - 1);
    
    // lets get +1 of the current Card index
    let index = parseInt(cardData.index, 10);
    if(index < 2 ){
      index = 0;
      props.resetSidebar(true);
      props.positionModal(
        onBoardingData.dashboardOnboarding[index].modalPosition
      );
    } else { 
      index--;
    }
     
    // Dispatch actions to trigger sidbar menu, set currentPage for menu highlight and set modal position
    if(onBoardingData.dashboardOnboarding[index].activateMenu !== 'none'){
      
      props.triggerMenu(onBoardingData.dashboardOnboarding[index].activateMenu);
      props.setCurrentPage(onBoardingData.dashboardOnboarding[index].activateMenu);
      props.positionModal(
        onBoardingData.dashboardOnboarding[index].modalPosition
      );
      if(window.innerWidth > 1000){ //make sure that this is not a mobile phone
        const activeElementOffset = getOffset(document.querySelector(`#sidebar-item-${index}`));
        
        document.getElementById(`card-${index}`).style.width =  '25.3%';
        document.getElementById(`card-${index}`).style.position = 'fixed';
        document.getElementById(`card-${index}`).style.top = (activeElementOffset.top+20)+"px";
      }
    }
      
  
    
    
  };
  const skipTourAction = ()=>{
    props.skipTour();
    props.updateUserData({seenModal : true, userId: userData.id });
    props.history.push('/courses');
  };
  return (
    
    <Fragment>
      {
        (props.userData.user && 
            props.userData.user.seenModal) ? <div> </div> :
              
          <Modal
            show={props.walkthroughProps.showModal}
            backdrop="static"
            dialogClassName={` modal-90w walkthrough ${!props.walkthroughProps.centerModal && window.innerWidth > 1000 ? 
              'card-position-left' : ''}`}
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <Modal.Body>
              {
           
                onBoardingData.dashboardOnboarding.map((item, index) => {
              
                  return (
                    <Card 
                      style={{borderRadius: '15px'}}
                      id={`card-${index}`}
                      className={
                        `border-0 carousel-card  ${index === currentSlide ? 'active' : ''}`} 
                      key={index}>
                      {item.hasBackground ? <Card.Img variant="top" src={item.image} className={`${item.bg} h-200`} /> : ''}
                      
                      <Card.Body className={`${item.hasBackground ? 'pl-5 pr-5' : 'pl-3 pr-3' } pb-3 pt-3`}>
                        {onboardingContent(item)}
                        <div className={`mt-3 pb-3  ${item.hasBackground
                          ? 'buttonSpaceBetween' :
                          'buttonAlignEnd pr-3'}`}>
                          { item.hasBackground ?
                            <Button  bsPrefix="a" href="#" className={`${item.hasBackground ?
                              '' : 'text-fade' } pl-0 sme-btn-link-white mr-2`}
                            onClick={skipTourAction}>
                          Skip Tour</Button> : ''}
                          {!item.hasBackground ? 
                            <Button 
                              className={`${item.hasBackground ? 
                                'sme-btn-primary walkthroughButton' : 
                                'sme-btn-white mr-2'}  `} 
                              data-index={index}
                              onClick={decreaseWalk}>
                          Prev
                            </Button> : ''}
                          <Button 
                            className={`${item.hasBackground ? 
                              'sme-btn-primary walkthroughButton no-border' : 
                              'sme-btn-white '}  `} 
                            data-index={index}
                            onClick={updateWalk}>
                            {item.hasBackground} 
                            {updateButtonText()}
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })
              }
            </Modal.Body>
          </Modal>
      }
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    walkthroughProps: { ...state.walkThroughState },
    userData: { ...state.userState }
  };
};
const mapDispatchToProps = dispatch => {
  return {
    triggerMenu: params => dispatch(triggerMenu(params)),
    skipTour: () => dispatch(skipTour()),
    positionModal: params => dispatch(positionWalkthrough(params)),
    showModal: () => dispatch(hideModal()),
    setCurrentPage: params=> dispatch(setCurrentMenu(params)),
    resetSidebar: param => dispatch(reset(param)),
    updateUserData: params => dispatch(updateUser(params)) // update user tour info 
  };
};
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DashboardOnboardingScreen));