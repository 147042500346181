import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import http from "../../../helpers/http";
import apiRoutes from "../../../constants/apiRoutes";
import { connect } from "react-redux";
import { replaceDynamics } from "../../../helpers/url";
import PageLoader from "../../PageLoader/PageLoader";
import NotFoundPage from "../NotFound/NotFound";
import "./StudentCourseDetail.scss";
import { Tab, Tabs } from "react-bootstrap";
import Loader from "../../../components/Loader/Loader";
import { setCurrentMenu } from "../../../store/actions/coreActions";
import {
  hasSubscribedToCourse,
  setTitleAndScrollToTop,
} from "../../../helpers/utility";

// const SubscribeToPackage = ({course, latestSubscription}) => (
//     <Link  className={` no-border btn  lift sme-btn-primary mr-2 paymentBtn rounded paymentButton`}
//            to={updateQueryParams('/subscribe-package', { itemId: course.id })}>
//         <img alt="courseImage"
//              style={{height:'31px', marginRight: '12px'}}
//              src={require('../../../assets/img/course/coins.png')}/>
//         {
//           latestSubscription ? 'Renew Subscription' : 'Subscribe To Package'
//         }
//     </Link>
// );

const SubscribeToCourse = ({ course, history }) => {
  const [loading, setLoading] = useState(false);
  const requirePayment =
    course &&
    !(course.is_free || (course.subscription || {}).payment_id) &&
    !!course.cost;
  const onClick = () => {
    if (!requirePayment) {
      setLoading(true);
      return http
        .put(apiRoutes.COURSE_LIST_WITH_SUB, { course_id: course.id })
        .then((result) => {
          history.push(`/classroom/${result.data.data.id}`);
        })
        .catch()
        .finally(() => setLoading(false));
    }
    return history.push(`/subscribe/${course.id}`);
  };
  return (
    <button
      onClick={onClick}
      className="no-border btn  lift sme-btn-primary mr-2 rounded paymentButton"
    >
      {requirePayment ? (
        <img
          alt="courseImage"
          style={{ height: "31px", marginRight: "12px" }}
          src={require("../../../assets/img/course/coins.png")}
        />
      ) : null}
      <span>Subscribe To Course</span>
      {loading ? <Loader scale={0.5} /> : null}
    </button>
  );
};

const WatchNow = ({ course }) => {
  return (
    <Link
      className={`no-border btn  lift sme-btn-primary mr-2 addBtnArrow paymentButton rounded`}
      to={`/classroom/${course.subscription.id}`}
    >
      {(course.subscription.progress || {}).percentageCompleted === 0
        ? "Start Course"
        : "Continue Course"}
    </Link>
  );
};

const CourseDetailContent = ({ course, history }) => {
  const [key, setKey] = useState("Overview");

  const placeHolder = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEA
  AAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=`;
  const { module_topic } = course.modules[0] || [];
  const trpLinks = [
    {
      id: "overview",
      text: "Overview",
      link: "#",
    },
  ];
  return (
    <>
      <div className="col-md-8 col-sm-12 mx-auto p-0">
        {(module_topic && module_topic.length ? module_topic[0] : {})
          .resource ? (
          <iframe
            title={course.title}
            src={`${
              (module_topic[0] || []).resource
            }?autoplay=1&loop=1&autopause=0width=640&height=360`}
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}
                              html,body{height:100%}img,span{position:absolute;width:100%;
                              top:0;bottom:0;margin:auto}span{height:1.5em;
                                text-align:center;font:48px/1.5 sans-serif;color:white;
                                text-shadow:0 0 0.5em black}</style>
                              <a href=${
                                (module_topic[0] || []).resource
                              }?autoplay=1&loop=1&autopause=0width=640&height=360>
                              <img src=${course.image || placeHolder} alt=${
              course.title
            }>
                              <span>▶</span></a>`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-100 normalplayer"
          />
        ) : (
          <img
            src={`${course.image || placeHolder}`}
            alt={`${course.title}`}
            className="w-100"
            style={{ height: "25rem", objectFit: "cover" }}
          />
        )}
      </div>
      <div className="col-md-8 col-sm-12 mx-auto bg-white w-100 tab-holder px-4 mt-4">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey={trpLinks[0].text} title={trpLinks[0].text} key={0}>
            {hasSubscribedToCourse(course) ? (
              <WatchNow course={course} />
            ) : (
              <SubscribeToCourse course={course} history={history} />
            )}
            <div className="py-3 overview">
              <b>About Course</b>
              <p className="mt-3">{course.description}</p>

              <b>Syllabus</b>

              <br />
              <br />
              {(course.modules || []).map((m, i) => {
                return (
                  <div key={i}>
                    <b>
                      Module {i + 1}: <span>{m.title}</span>
                    </b>
                    <p className="mt-3">{m.description}</p>
                  </div>
                );
              })}
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

const CourseDetail = ({ history, dispatch }) => {
  let { id } = useParams();
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTitleAndScrollToTop('Course');
    dispatch(setCurrentMenu(""));
    setLoading(true);
    http
      .get(replaceDynamics(apiRoutes.COURSE_DETAIL_WITH_SUB, { id }))
      .then((result) => {
        let course = result.data.data;

        setTitleAndScrollToTop(course.title);

        setCourse({
          ...course,
          // arrange modules by created_at
          modules: course.modules.sort((a, b) => {
            const x = new Date(a.created_at).getTime();
            const y = new Date(b.created_at).getTime();
            return x - y;
          }),
        });
      })
      .catch()
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className=" row  StudentCourseDetail">
      <div className="col-12">
        {loading ? (
          <PageLoader />
        ) : course ? (
          <CourseDetailContent course={course} history={history} />
        ) : (
          <NotFoundPage
            title="Course not found"
            subtitle="Oops!, it seems the course you are looking for does not exist or has been removed"
            goto="/courses"
            cta="See all courses"
          />
        )}
      </div>
    </div>
  );
};

export default connect()(CourseDetail);
