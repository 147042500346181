import http from '../../helpers/http';
import apiRoute from '../../constants/apiRoutes';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Search.scss';
import { updateQueryParams } from '../../helpers/url';


const SearchBar = () => {
  const [items, setItems] = useState([]);
  const user = useSelector(state => state.userState.user);
  /**
   * Clear search box and it items.
   */
  const clearSearch = () => {
    document.querySelector('.search').value = "";
    setItems([]);
    document.querySelector('.search-content').classList.add('d-none');
  };
  /**
   * Fetch data from server and display it
   * @param {*} e 
   */
  const findData = (e) => {
    const query = e.target.value;
    if (query.length) {
      http.get(updateQueryParams(apiRoute.SEARCH_COURSE, { keyword: query })).then(results => {
        const data = results.data.data.map((result, index) => {
          if (result.topicCount > 0) {
            return (<li key={index}><Link key={index} onClick={clearSearch} to={{
              pathname: `/courses/${result.slug}`,
              params: { id: result.id },
              state: {
                course: { ...result },
                clientType: user.role.name
              }
            }}>
              {result.title}
            </Link></li>);
          }
          return null;
        }
        );
        setItems(data);
        document.querySelector('.search-content').classList.remove('d-none');
      });
    }
  };
  /**
   * Hide other menu items beside searchbox
   */
  const hideOtherMenuOptions = () => {
    if (window.innerWidth < 520) {
      document.querySelector('.dropdown').classList.toggle('d-none');
      document.querySelector('.bgSwitch').classList.toggle('d-none');
      document.querySelector('.mobileMenu').classList.toggle('d-none');
    }
  };

  /**
   * Show search box
   */
  const seeOptions = () => {
    hideOtherMenuOptions();
    if (!document.querySelector('.search').classList.contains('searchActive')) {
      document.querySelector('.search').classList.add('searchActive');
    } else {
      document.querySelector('.search').classList.remove('searchActive');
      clearSearch();
    }
  };

  return (
    <Fragment>
      <div className="searchForm">
        <div className="holder w-100">
          <input onKeyUp={e => findData(e)}
            type="text" className="search"
            placeholder="Search for course..." required />
          <i alt="logo" className="icon searchButton fa fa-search" onClick={seeOptions}></i>
        </div>
        <div className="search-content bg-white  d-none">
          <ol>
            { items.map(item => item) }
          </ol>
        </div>
      </div>


    </Fragment>

  );
};
export default SearchBar;