import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CourseList from "../../../components/CourseList/CourseList";
import CourseProgress from "../../../components/CourseProgress/CourseProgress";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Discussions from "../../../components/DicussionCard/Discussions";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { DiscussionShimmer } from "../../../components/Shimmer/shimmerArticle";
import UpcomingEvents from "../../../components/UpcomingEvents/UpcomingEvents";
import { updateQueryParams } from "../../../helpers/url";
import utilityHelper from "../../../helpers/utility";
import {
  getCategories,
  getCourseListWithSub,
  setCurrentMenu,
  setSectionTitle,
} from "../../../store/actions/coreActions";
import { loadDiscussions } from "../../../store/actions/discussionActions";
import styles from "./StudentHome.module.scss";

const FilterCourse = ({ categories, category, location, history }) => {
  const onFilterChanged = (event, value) => {
    history.push(
      updateQueryParams(`${location.pathname}${location.search}`, {
        category: value || null,
      })
    );
  };

  if (!categories) {
    return null;
  }

  return (
    <div className={`${styles.filterComponent}`}>
      <CustomSelect
        onChange={onFilterChanged}
        options={categories}
        defaultOption={category}
      />
    </div>
  );
};

const StudentHome = (props) => {
  const search = useLocation().search;
  const category = new URLSearchParams(search).get("category");

  React.useEffect(() => {
    utilityHelper.setTitleAndScrollToTop(
      "Dashboard:",
      (props.user || {}).first_name,
      null
    );
    props.loadCategories();
    props.loadDiscussions();
    props.setCurrentPage("dashboard");
    props.setSectionTitle({
      title: `All Courses`,
      hasHr: false,
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    props.loadCourses({ category, limit: 6 });
    // eslint-disable-next-line
  }, [category]);

  const ShimmerPlacholder = () => (
    <Col md={7} sm={12} className="mt-4">
      <Row className="mt-3 mb-2">
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center discussion-title-bar"
        >
          <span>Latest Discussions</span>
        </Col>
      </Row>
      <Row>
        <DiscussionShimmer maxCards="2" />
      </Row>
    </Col>
  );

  const discussionSection = () => {
    const discussions = [];
    discussions.discussions = [...props.discussions.discussions.slice(0, 3)];

    return (
      <Discussions
        showPagination={false}
        colSize={7}
        className={`${styles.order2}`}
        dispatch={props.dispatch}
        addHeader={true}
        discussionList={discussions}
        viewCommunity={true}
      />
    );
  };

  return (
    <React.Fragment>
      <div className={`col-12 pb-5  p-0 min-content-height`}>
        <div className={`col-12 ${styles.Header} p-0`}>
          <div className={styles.HeaderContent}>
            <div className="d-none d-lg-block">
              <PageTitle />
            </div>
            <FilterCourse
              categories={props.categories}
              category={category || ""}
              history={props.history}
              location={props.location}
            />
          </div>
        </div>

        <CourseList
          courseListWithSub={props.courseListWithSub || {}}
          loadingCourse={props.loadingCourses}
          paginate={true}
        />

        <div className={`col-12 p-0`}>
          <Row className={`${styles.divSwitch}`}>
            {!props.loadingDiscussions
              ? discussionSection()
              : ShimmerPlacholder()}
            <Col md={5} sm={12} className="d-flex flex-column ">
              <Row>
                <Col
                  md={12}
                  className="p-0 "
                  style={{ color: "transparent", height: "40px" }}
                >
                  <span>Latest Discussions</span>
                </Col>
              </Row>
              <div className="mt-4">
                <CourseProgress />
              </div>
              <div>
                <UpcomingEvents />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    loadingCourses: state.coreState.loadingCourse,
    courseListWithSub: state.coreState.courseListWithSub,
    categories: state.coreState.categories,
    loadingDiscussions: state.discussionState.discussionLoading,
    discussions: state.discussionState.discussionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourses: (params) => dispatch(getCourseListWithSub(params)),
    setCurrentPage: (params) => dispatch(setCurrentMenu(params)),
    loadCategories: () => dispatch(getCategories()),
    loadDiscussions: () => dispatch(loadDiscussions()),
    setSectionTitle: (params) => dispatch(setSectionTitle(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentHome);
