import React from 'react';
import './Shimmer.scss';
import { Col, Card, Row } from 'react-bootstrap';
const Shimmer = (props) => {
  const cards = [...Array(parseInt(props.maxCards || 2, 10)).keys()].map(x => {
    return (<Col sm={12} md={4} lg={4} className="pt-4 shimmer" key={x}>
      <div className="shine box" 
        style={{height: '10rem',width: '100%',
          display:'block'}}>
      </div>
      <div className="p-4 bg-white w-100 lift">
        <div className="col-sm-12 p-0 mt-3 longlines">
          <div className="shine lines" ></div>
          <div className="shine lines" ></div>
          <div className="shine lines" ></div>
        </div>
        <div className="col-sm-12 p-0 mt-1 d-flex justify-content-between">
          <div 
            className="shine box mt-3" style={{height: '13px',width: '10%', display:'block'}}></div>
          <div 
            className="shine box mt-3" style={{height: '13px',width: '10%', display:'block'}}></div>
        </div>
        <div 
          className="shine box mt-3"
          style={{height: '33px',width: '60%', display:'block', borderRadius: '6px'}}>
              
        </div>
      </div>
    </Col>);
  
  });
  return (
    cards
  );
};
export const DiscussionShimmer = props => {
  const cards = [...Array(parseInt(props.maxCards || 2, 10)).keys()].map(x => {
    return (
      <Col md={12} sm={12} key={`discussion-col-${x}`} >
        <Card 
          className={`border-0 rounded mb-3`} 
          key={`discussion-item-`}>
          <Card.Header className="discussion-card-header d-none d-lg-block">
            <Row>
              <Col md={7} >
                <div className="d-flex align-items-center ">
                  <div className="shine box" 
                    style={{height: '24px',width: '24px',
                      display:'block'}}>
                  </div>
                
                </div>             
              </Col>
              { 
                <Col md={5}  className="rounded" >
                  <div className="col-sm-12 p-0  longlines">
                    <div className="shine lines" ></div>
                  </div>
                </Col>
              }   
            </Row>
          </Card.Header>
          <Card.Body>
            <div className={`mt-3 mb-2 discussion-card pl-3 pr-3`}>
              <div className="col-sm-12 p-0 mt-3 longlines">
                <div className="shine lines" ></div>
                <div className="shine lines" ></div>
                <div className="shine lines" ></div>
                 
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  return (
    cards
  );
};
export default Shimmer;