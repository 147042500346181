import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import allEvents from '../../../components/UpcomingEvents/allEvents';
import UpcomingCalendarEvents from '../../../components/UpcomingEvents/UpcomingCalendarSection';
import CalendarDetails from '../../../components/UpcomingEvents/UpcomingDetails';
import utilityHelper from "../../../helpers/utility";
import {
  setCurrentMenu, setSectionTitle
} from "../../../store/actions/coreActions";
import { loadEvents } from '../../../store/actions/eventsActions';
import styles from "./Calendar.module.scss";


class Calendar extends React.Component{

  componentDidMount(){
    utilityHelper.setTitleAndScrollToTop("Calendar");
    this.props.setCurrentPage('Calendar');
    this.props.setSectionTitle({
      title:`Calendar`,
      hasHr: false
    });
    this.props.getEvents();
    document.querySelector('#header #pageTitle .page-title').innerText = 'Back to Upcoming Events';
  }
  render(){
    return (
      <div className={`col-12 pb-5   min-content-height`}>
        <div className={`col-12 ${styles.Header} p-0`}>
          <div className={styles.HeaderContent} >
            <div className="d-none d-lg-block">
              <br/>
            </div>
          </div>
        </div>

        <Fragment>
          <Switch>
            <Route path={`${this.props.match.path}/`} component={UpcomingCalendarEvents} exact />
            <Route path={`${this.props.match.path}/all`} component={allEvents} exact />
            <Route path={`${this.props.match.path}/:id`} component={CalendarDetails} exact/>
          </Switch>
        </Fragment>

      </div>
    ); 
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setCurrentPage: params=> dispatch(setCurrentMenu(params)),
    setSectionTitle: params =>dispatch(setSectionTitle(params)),
    getEvents: ()=> dispatch(loadEvents())
  };
};
export default connect(null, mapDispatchToProps)(Calendar);