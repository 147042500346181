import React from 'react';
import { Card, Col } from 'react-bootstrap';
import './UpcomingEvents.scss';
import '../../assets/scss/custom-buttons.scss';
import { Link } from 'react-router-dom';
import { LazyImage } from '../CourseItem/LazyLoadImage';
const EventCard = props => {
 
  return (
    <Col md={4} key={props.index}>
      <Card className="mt-4 rounded border-0 single-event-card lift">
        <LazyImage
          src={props.event.image}
          alt={props.event.title} className={'w-100 h-100 card-img-top'}/>
        
        <Card.Body >
          <Card.Title className="event-title">{props.event.title}</Card.Title>
          <Card.Text className="event-desc">{props.event.desc}</Card.Text>
          <Link to={{
            pathname: `/calendar/${props.event.slug}`,
            state: {event:  {...props.event}}
          }}
          className={`sme-btn-primary rounded seeAll btn-block mt-4 p-3 text-center no-lift`}
          style={{ textDecoration: 'none' }} >
                  View Details
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default EventCard;