import React, { useState, useRef} from 'react';
import { Form, Button } from 'react-bootstrap';
import './Discussion.scss';
import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { connect } from 'react-redux';
import Loader from '../Loader/Loader.js';
import moment from 'moment';

import ShowSnackbar from '../UI/Snackbar/SnackbarNoRedux';


const ReplySection = props => {
  const [loading, setLoading] = useState(false);
  const ref = useRef(`comment-holder-${props.discussion.id}`);
  const commentBoxRef = useRef(`commentBox-${props.discussion.id}`);
  const [ openSnackBar, setOpenSnackBar] = useState(false);
  const [ networkMessage, setNetworkMessage] = useState('');



  const saveCommentData = e => {
    // const code = (e.keyCode ? e.keyCode : e.which);
    // if (code == 13) { //Enter keycode
    const option = {
      content: document.getElementById(commentBoxRef.current.id).value
    };
    if(document.getElementById(commentBoxRef.current.id).value.length > 0){
      setLoading(true);
      http.post(apiRoutes.COMMENT_ON_DISCUSSION.replace('@id', e.target.id), option).then(response => {
        setLoading(false);
        const result = response.data.data;
        const li = document.createElement('li');
        const div = document.createElement('div');
        const headerDiv = document.createElement('div');
        headerDiv.setAttribute('class','d-flex justify-content-between align-item-center');
          
        const b = document.createElement('b');
        const timeSpan = document.createElement('span');
        const p = document.createElement('p');
  
        p.innerHTML = result.content;
        b.innerText = `${props.user.first_name} ${props.user.last_name}`;
        timeSpan.innerHTML = moment(new Date(result.created_at)).fromNow();
  
        headerDiv.appendChild(b);
        headerDiv.appendChild(timeSpan);
  
        div.appendChild(headerDiv);
        div.appendChild(p);
  
        li.appendChild(div);
        document.getElementById(ref.current.id).children[0].prepend(li);
        document.getElementById(commentBoxRef.current.id).value= '';
        setNetworkMessage(response.data.message);
        setOpenSnackBar(true);
      });
    } else{
      setNetworkMessage("Comment cannot be empty.");
      setOpenSnackBar(true);
    }
      

    // }
  };

  const parseComments = (comments) => {
   
    let commentList = [];
    commentList.push(comments.map((comment, index) => {
      return <li key={index} className="mt-2">
        <div>
          <div className="d-flex justify-content-between align-item-center">
            <b>{`${comment.User.first_name} ${comment.User.last_name}`}</b>
            <span>{`${moment(new Date(comment.created_at)).fromNow()}`}</span>
          </div>
          <p>{comment.content}</p>
        </div>

      </li>;
    }));
    // console.log(comment)
    return commentList;
  };
  const handleClose = ()=>setOpenSnackBar(false);
  return (
    <div className={`${props.show ? 'd-none' : 'd-block'}`} >
      {<ShowSnackbar vertical="bottom" horizontal="left" handleClose={()=>handleClose()} message={networkMessage} openSnackBar={openSnackBar}/>}
      <div ref={ref} className={`comment-holder d-flex flex-column `} id={`comment-holder-${props.discussion.id}`}>
        <ol className="comments mb-0 px-3">
          {parseComments(props.comments)}
        </ol>
     
        <Form.Group className="mb-0" >
          <div className="d-flex justify-content-between  pl-0 p-1">
            <Form.Control
              ref={commentBoxRef}
              size="sm"
              type="text"
              id={`comment-${props.discussion.id}`}
              className={`commentBox-${props.discussion.id} px-4 rounded-0`}
              placeholder="Enter a comment" 
            />
            {!loading ? <Button style={{borderRadius: '0px'}}className=" no-lift sme-btn-primary  " id={props.discussion.id} onClick={saveCommentData}>
              Post
            </Button>
              :''}
          </div>
          
            
          {loading ? <Loader style={{ position: 'absolute', right: '-10px', marginTop: '-20px' }} color="#0062FF" scale={0.4} type={props.type || "ripple"} /> : ''}
        </Form.Group>

      </div>
    </div>
  );
};
const mapPropsToState = state =>{
  return{
    user: state.userState.user
  };
};

export default connect(mapPropsToState)(ReplySection);