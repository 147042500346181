import React from 'react';

const NoEventView = ()=> {
  return (
    <div className={`col-12 d-flex pt-5 flex-column justify-content-center 
    align-items-center m-auto`}>
      <img src={require("../../assets/img/no-activity.png")} 
        alt="" className="noCourseImg"/>
      <h1 className="noDiscussionH1 mt-3">No Activity</h1>
      <p className="mb-0 noDiscussionP mt-3 mb-5 "  >
      We couldn’t find any event!!
      </p>
      
    
    </div>
  );
};
export default NoEventView;