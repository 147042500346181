import * as actionType from "../actions/eventsActions";
const initialState = {
  allEvents: {
    loading: true,
    events: []
  },
  upcomingEvents: {
    loading: true,
    events: []
  }
 
};
let updatedEventList;
const eventReducer = (state = initialState, action) => {
  
  switch(action.type){
    case actionType.LOAD_EVENTS:
      state.allEvents.events = [ ...action.payload.events.events ];
      state.allEvents.loading = action.payload.loading;
      return  { ...state };
    case actionType.LOAD_UPCOMING_EVENTS:
      state.upcomingEvents.events = [ ...action.payload.upcomingEvents.events ];
      state.upcomingEvents.loading = action.payload.loading;
      return  {
        ...state,
        upcomingEvents: {
          ...state.upcomingEvents,
          next_page: action.payload.upcomingEvents.next_page,
          number_of_pages: action.payload.upcomingEvents.number_of_pages,
          total: action.payload.upcomingEvents.total,
          limit: action.payload.upcomingEvents.limit,
          page: action.payload.upcomingEvents.page,
          url: action.payload.upcomingEvents.url
        } 
      };
    case actionType.UPDATE_EVENTS:
      updatedEventList = state.upcomingEvents.events
        .concat([ ...action.payload.upcomingEvents.events]);
      state.upcomingEvents.loading = action.payload.loading;
      return  {
        ...state,
        upcomingEvents: {
          ...state.upcomingEvents,
          events: updatedEventList,
          next_page: action.payload.upcomingEvents.next_page,
          number_of_pages: action.payload.upcomingEvents.number_of_pages,
          total: action.payload.upcomingEvents.total,
          limit: action.payload.upcomingEvents.limit,
          page: action.payload.upcomingEvents.page,
          url: action.payload.upcomingEvents.url
        } 
      }; 
    default:
      return state;
  }
};
export default eventReducer;