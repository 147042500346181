import React, { Fragment } from 'react';
import {Button} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {  withRouter, Link } from "react-router-dom";
import { logOut } from '../../helpers/utility';


const MenuItem = (props )=> {
  const highlightMenu = useSelector(state => state.walkThroughState.menuToTrigger);
  const resetMenu = useSelector(state => state.walkThroughState.resetMenu);
  const currentPage = useSelector(state => state.coreState.currentPage);
  const toggleMenu = (e)=>{
    if(e.target.id === 'logout'){
      logOut(props.history);
      return ;
    }
    const sidebar =  document.querySelector('.sidebar').classList;
    if(sidebar.contains('d-none')){
      sidebar.add('d-block');
      sidebar.remove('d-none');
    }else{
      sidebar.remove('d-block');
      sidebar.add('d-none');
    }
  };

  const linkItem = ()=>{
    return (
      <li key={props.id}  id={`${props.isMobile ? 'mb-' : ''}sidebar-item-${props.index}`}
        className={`sme-sidebar-item
       ${highlightMenu.toLowerCase() === props.did.toLowerCase()
        && !resetMenu ? 'activeButton' : ''}`}>
        <Link to={{
          pathname: props.id,
          state: { fromDashboard: true }
        }} className={`${ currentPage.toLowerCase() === props.did.toLowerCase() ? 
          'menu-active ' : ''} lift btn btn-link `}
        onClick={toggleMenu} id={props.did}>
          <Fragment>
            <img src={props.icon}
              className="img img-fluid  mr-4" alt={props.title} /> 
            {props.title}
          </Fragment>
           
        </Link>
      </li>
    );
  };
  const headerItem = ()=>{
    return (
      <li key={props.id} className="sme-sidebar-header-item">
        <Button variant="link"> 
          {props.title}
        </Button>
      </li>
    );
  };
  
  return (
      
    props.itemType === 'header-item' ? headerItem() : linkItem()
    
  );
};
export default withRouter(MenuItem);