import React from "react";
import styles from "./Loader.module.scss";

const Loader = ({scale, type, color, style = {}}) => {
  scale = +scale;
  scale = Number.isNaN(scale) ? 1 : scale || 1;
  const scaleStyle = {transform: `scale(${scale})`, ...style};
  return type === "ripple" ? (
    <div className={styles.ripple} style={scaleStyle}>
      <div style={{borderColor: color || '#4E4E56'}}/>
      <div style={{borderColor: color || '#4E4E56'}}/>
    </div>
  ) : (
    <div className={styles.linear} style={scaleStyle}>
      <div style={{background: color || '#4E4E56'}}/>
      <div style={{background: color || '#4E4E56'}}/>
      <div style={{background: color || '#4E4E56'}}/>
    </div>
  );
};

export default Loader;
