import React, { useState } from 'react';
import {  withRouter } from "react-router-dom";
import  { Row, Col } from 'react-bootstrap';
import './Pagination.scss';
import { useDispatch } from 'react-redux';
import {UPDATE_COURSE_LIST, UPDATE_COURSE_LIST_WITH_SUB} from '../../store/actions/coreActions';
import http from '../../helpers/http';
import { updateQueryParams } from '../../helpers/url';
import { getListFromResponse } from '../../helpers/utility';
import { UPDATE_EVENTS } from '../../store/actions/eventsActions';
import { APPEND_DISCUSSION } from '../../store/actions/discussionActions';
import Loader from '../Loader/Loader';
import { UPDATE_SUB } from '../../store/actions/userActions';



function Pagination(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const numberOfPages = (props.list || {}).number_of_pages || 1;
  //   const pages = [...Array(numberOfPages).keys()].map(x => x);
  const currentPage = (props.list || {}).page || 1;
  //   const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const source = (props.list || {}).url || props.url;
  const isOnLastPage = () => (currentPage >= numberOfPages);
  const fetchMore = ()=>{
    // if we have where to load from and we are not on the last page
    if(source.length && !isOnLastPage()) {
      const url = updateQueryParams( source, {
        addToken: false,
        page: nextPage,
        paginate: 1,
      });
      setLoading(true);
      http.get(url).then(response=>{
        setLoading(false);
        switch(props.updateWho){
          case 'courseWithSub':
            dispatch({
              type: UPDATE_COURSE_LIST_WITH_SUB,
              payload: {courseListWithSub : getListFromResponse(response, 'courses'), loadingCourse: false}
            });
            break;
          case 'sub':
            dispatch({
              type: UPDATE_SUB,
              payload: {courseList : getListFromResponse(response, 'courses'), loadingUserCourse: false}
            });
            break;
          case 'course':
            dispatch({
              type: UPDATE_COURSE_LIST,
              payload: {courseList : getListFromResponse(response, 'courses'), loading: false}
            });
            break;
          case 'event':
            dispatch({
              type: UPDATE_EVENTS,
              payload: {upcomingEvents : getListFromResponse(response, 'events'), loading: false }
            });
            
            break;
          case 'discussions':
            dispatch({
              type: APPEND_DISCUSSION,
              payload: {discussions : getListFromResponse(response, 'discussions'), loading: false }
            });
            
            break;
          default:
            break;
        }
        
      });
    }
  };
  return (
    <Row className={isOnLastPage() ? 'd-none' : 'd-block' }>
      <Col  md={12} >
        { loading ?
          <Loader scale="0.5"/> :
          <div  className={` mt-2 py-4 pagination-band d-flex justify-content-center`}>
            <hr className="m-0 "/>
            <span
              className={`px-3 pagination ${isOnLastPage() ? 'disabled' : ''}`}
              onClick={fetchMore}>+  {props.title} </span>
          </div>
        }
      </Col>
    </Row>
  );
}
export default withRouter(Pagination);
