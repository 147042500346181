import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../../../assets/img/no-activity.png';
class NotFoundPage extends React.Component{
  render(){
    return<div className="row h-100">
      <div className={`col-12 d-flex py-5 flex-column justify-content-center align-items-center mx-auto`}>
        <img src={PageNotFound}
          alt="page not found" className="noCourseImg"/>
        <h1 className="noDiscussionH1 mt-3">{this.props.title || 'Page Not Found'}</h1>
        <p className="mb-0 noDiscussionP mt-3 mb-5 "  >
          { this.props.subtitle || 'Sorry, it seems the page you are looking for does not exist or has been moved to a different location.'}
        </p>
      
        <Link to={this.props.goto || '/'}>
          <button className="btn sme-btn-primary font-weight-600 no-border" style={{width: '15rem'}}>
            {this.props.cta || 'Goto Home'}
          </button>
        </Link>
      </div>
    </div>;
    
  }
}
export default NotFoundPage;
