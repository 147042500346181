import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducer";
import coreReducer from "./reducers/coreReducer";
import walkthroughReducer  from "./reducers/walkthroughReducer";
import discussionReducer from "./reducers/discussionReducers";
import eventReducer from "./reducers/eventReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
const combinedReducer = combineReducers({
  coreState: coreReducer,
  userState: userReducer,
  walkThroughState: walkthroughReducer,
  discussionState: discussionReducer,
  eventState: eventReducer
});
// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(combinedReducer, composeWithDevTools(
  applyMiddleware(thunk)
));
// const store = createStore(combinedReducer,applyMiddleware(thunk));
export default store;
