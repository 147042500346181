import cookie from "./cookie";
import {updateQueryParams} from './url';
// import {SHOW_SNACKBAR} from "../store/actions/coreActions";
import { attachAccessTokenToUrlQueryParams, extractQueryParams, hardRedirect } from "./url";

export const logOut = (clearAuthCookies = true, redirectPath = '') => {
  if(clearAuthCookies) {
    cookie.remove('accessToken');
    cookie.remove('refreshToken');
  }
  let url =  `${process.env.REACT_APP_BASE_SERVICE_URL}/auth/logout`;
  if (redirectPath) {
    url = updateQueryParams(url, {redirectPath});
  }
  hardRedirect(url);
};
export const objectsEqual = (o1, o2) => {
  return typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};


export  const arraysEqual = (a1, a2) => {
  return a1.length === a2.length &&
  a1.every((o, idx) => objectsEqual(o, a2[idx]));
};


export const  getRandomColor  = () => {
  const catBg = [
    {background: 'rgba(255, 104, 104, 0.1)', color:'#FF6868'},
    {background: 'rgba(255, 193, 104, 0.1)', color: '#FFA82B'},
    {background: 'rgba(87, 208, 148,.12)', color: '#51C98E'},
    {background: 'rgba(154, 104, 255, .12)', color: '#525CE5'},
    {background: 'rgba(87, 208, 148, .12)', color:'#51C98E'},
    {background: 'rgba(104, 220, 255,.12)', color:'#0DBBF0'},
    {background: 'rgba(154, 104, 255,.12)', color:'#9A68FF'},
    {background: 'rgba(255, 149, 104,.12)', color: '#FF9568'}
  ];

  let randomNum = Math.floor(Math.random() * (catBg.length - 0) + 0);
  return { ...catBg[randomNum] };
};
export const getSocialShareTextFromTemplate = courseName => {
  return `I strongly recommend that you take this course (${courseName}) ` +
  `on SMEShop, it helped me achieved my career goals.`;
};

export const getHashTag = (...tags) => {
  tags = tags || [];
  tags = [
    ...tags,
    'smeshop',
    'tbwa'
  ];
  return tags.join(", ");
};

export const setTitleAndScrollToTop = (...title_segments) => {
  setTitle(...title_segments);
  scroll(0, 0);
};

export const setTitle = (...title_segments) => {
  title_segments = (title_segments || [])
    .filter(s => !(s === null || s === undefined || s === ""));
  title_segments.push("SME: Online Courses");
  document.title = title_segments.join(' | ');
};
export const getPageTitle = ()=>{
  return document.title;
};
// export const processHTTPError = error => {
//   if(error.response && error.response.data) {
//     return error.response.data.data || error.response.data.message;
//   }

//   if(error.message) {
//     store.dispatch({
//       type: SHOW_SNACKBAR,
//       payload: {
//         config: {
//           message: error.message,
//           verticalAlign: "top",
//           horizontalAlign: "right"
//         }
//       }
//     });
//   }
//   return null;
// };

export const scroll = (x = 0, y = 0) => {
  window.scrollTo(x, y);
};

export const isAuthenticated = () => {
  return  !!cookie.get('accessToken');
};

export const getUserinformation = state => {
  return state.userState.user;
};
export const postAuthHandler = (history, routeTo='/') => {
  const query = extractQueryParams((window.location || {}).href || '');
  if(query && 'redirect' in query) {
    let { redirect, requireIdentity } = query;
    redirect = decodeURIComponent(redirect);
    if(requireIdentity){
      redirect = attachAccessTokenToUrlQueryParams(redirect);
    }
    hardRedirect(redirect);
    return;
  }
  if(history) {
    history.push(routeTo);
  }
};

export const preventEventDefault = (event, prevent = true) => {

  if(event && prevent){
    event.preventDefault();
    event.stopPropagation();
  }
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  const parent = el.parentElement.getBoundingClientRect();
  return {
    left:  Math.floor(rect.left + window.scrollX),
    top: Math.floor(rect.top - parent.top )
  };
};

export const  disableForm = (el, disabled) => {
  document.querySelectorAll(el).forEach(function(a){
    a.disabled=disabled;
  });
};
export const getListFromResponse = (response, arrayName = '') => {
  const { data } = response.data;

  if(arrayName && data && data.results) {
    data[arrayName] = [...data.results];
    delete data.results;
  }
  return data;
};
export const smeFormatDate = (eventDate)=>{
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const event = new Date(eventDate);
  return {
    time: event.toLocaleTimeString('en-US'),
    date: `${event.toLocaleDateString("en-US", options)}`
  };
};
export const getValidUrl = (url = "") => {
  var pattern = /^((http|https|ftp):\/\/)/;
  if(!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
};
export const leadingZero = (num, size= 2) => {
  let s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
};

export const formatTime = totalMiliSeconds => {
  let totalSeconds = totalMiliSeconds / 1000;
  let h, m, s;
  h = Math.floor(totalSeconds/ (60 * 60));
  totalSeconds = totalSeconds - h * 60 * 60;
  m = Math.floor(totalSeconds / 60);
  s = totalSeconds - m * 60;
  if(h>0){
    return `${leadingZero(h)}:${leadingZero(m)}:${leadingZero(s)}`;
  }
  return `${leadingZero(m)}:${leadingZero(s)}`;
};

export const hasSubscribedToCourse = (course) => {
  return course && course.subscription && (course.is_free || course.subscription.payment_id);
};

export default {
  getPageTitle,
  setTitle,
  setTitleAndScrollToTop,
  scroll,
  isAuthenticated,
  postAuthHandler
};
