import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import apiRoutes from '../../constants/apiRoutes';
import http from '../../helpers/http';
import { SHOW_SNACKBAR } from '../../store/actions/coreActions';
import Loader from '../Loader/Loader';
import './PasswordPopup.scss';
import { connect } from "react-redux";
import {updateQueryParams} from "../../helpers/url";
import cookie from "../../helpers/cookie";
const PasswordPopup = props => {

  const [isLoading, setIsLoading]= useState(false);
  const [hasError, setHasError]= useState(false);
  const [error, setError]= useState([]);
  const [passwordNotSame, setPasswordNotSame] = useState(false);

  const user = useSelector(state => state.userState.user);
  const [smShow, setSmShow] = useState(user && !user.password);
  const passwordStrength = (event)=>{
    const myInput = event.target;

    if(myInput.value.length >= 8) {
      setHasError(false);
      setError([]);
      event.target.parentElement.children[0].style.display = 'none';
    } else{
      event.target.parentElement.children[0].style.display = 'block';
      setHasError(true);

    }
    // Validate length
    if(myInput.value.length <= 8) {
      if(!error.includes('Password length must be greater than 8')){
        setError([...error, 'Password length must be greater than 8']);
      }
    } else {
      error.splice(error.indexOf('Password length must be greater than 8'), 1);
    }



  };
  const isSamePassword= (event)=>{
    if(event.target.value !== document.getElementById('newpassword').value){
      setPasswordNotSame(true);
      event.target.parentElement.children[0].style.display = 'block';
    }else {
      setPasswordNotSame(false);
      event.target.parentElement.children[0].style.display = 'none';
    }
  };
  const saveProfileInformation = (event)=>{
    event.preventDefault();
    const data = new FormData(event.target);

    setIsLoading(true);
    http.post(
      updateQueryParams(apiRoutes.PASSWORD_RESET, {token: cookie.get("accessToken")}),
      data
    ).then(response => {
      setIsLoading(false);
      if(response.data.code === 200){
        setSmShow(false);
        props.dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: response.data.message}
        } });
      }
    });
  };

  return (
    <Modal
      size="md"
      show={smShow}
      onHide={() => setSmShow(false)}
      aria-labelledby="example-modal-sizes-title-lg "
      className="border-0 setPassword"
      centered
    >
      <Modal.Header className="bg-white" closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        Create Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        <Row>

          <Col md={12}>
            <Card className="border-0">
              <Card.Body>
                <Card.Title className="mb-4">
                    Hello {user.first_name}, thank you for registering on SME Shop.
                    Please create a new password to enable you login subsequently.
                </Card.Title>
                <Form onSubmit={saveProfileInformation} className="profileEdit form">

                  <Form.Group controlId="formNewPassword">
                    <Form.Label>New Password</Form.Label>
                    <div className="position-relative">
                      <div className="formMessage error" id="nameError">
                        <span className="text-center">{error.join(', ')} </span>
                      </div>

                      <Form.Control
                        onKeyDown={passwordStrength}
                        type="password"
                        name="password"
                        id="newpassword"
                        placeholder="New Password"
                        className={hasError ? 'error' : ''}/>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formConfirmPassword">
                    <Form.Label>Confirm New Password</Form.Label>
                    <div className="position-relative">
                      <div className="formMessage error" id="confirmError">
                        <span className="text-center">Password not the same </span>
                      </div>
                      <Form.Control
                        onKeyUp={isSamePassword}
                        type="password"name="confirmPassword"
                        placeholder="Confirm Password"
                        className={passwordNotSame ? 'error' : ''}/>
                    </div>
                  </Form.Group>
                  <Button
                    className="sme-btn-primary px-2 py-2 mt-2 d-flex
                    align-items-center flex-columns"
                    type="submit">
                          Update Password
                    <span className="ml-1">{isLoading ? <Loader scale="0.5"/> : '' }</span>
                  </Button>
                </Form>
              </Card.Body>
            </Card>

          </Col>

        </Row>

      </Modal.Body>
    </Modal>
  );
};

export default connect()(PasswordPopup);
