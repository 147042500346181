import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CourseList from "../../../components/CourseList/CourseList";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { updateQueryParams } from "../../../helpers/url";
import utilityHelper from "../../../helpers/utility";
import {
  getCategories,
  setCurrentMenu,
  setSectionTitle,
} from "../../../store/actions/coreActions";
import { getUserSubscribedCourses } from "../../../store/actions/userActions";
import styles from "./StudentCourses.module.scss";

const FilterCourse = ({ categories, category, location, history }) => {
  const onFilterChanged = (event, value) => {
    history.push(
      updateQueryParams(`${location.pathname}${location.search}`, {
        category: value || null,
      })
    );
  };

  if (!categories) {
    return null;
  }

  return (
    <div className={`${styles.filterComponent}`}>
      <CustomSelect
        onChange={onFilterChanged}
        options={categories}
        defaultOption={category}
      />
    </div>
  );
};

const StudentCourses = (props) => {
  const search = useLocation().search;
  const category = new URLSearchParams(search).get("category");

  React.useEffect(() => {
    utilityHelper.setTitleAndScrollToTop("My Courses");
    props.setCurrentPage("My Courses");
    props.loadCategories();
    props.setSectionTitle({
      title: `Enrolled Courses`,
      hasHr: false,
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    props.loadSubscribedCourses({ category });
    // eslint-disable-next-line
  }, [category]);

  return (
    <div className={`col-12 p-0 pb-5 min-content-height`}>
      <div className={`col-12 ${styles.Header} p-0`}>
        <div className={styles.HeaderContent}>
          <div className="d-none d-lg-block">
            <PageTitle />
          </div>
          <FilterCourse
            categories={props.categories}
            category={category || ""}
            history={props.history}
            location={props.location}
          />
        </div>
      </div>
      <CourseList
        courseListWithSub={props.courses}
        loadingCourse={props.loadingCourses}
        noCourseTitle={!category && "You have not started a course yet"}
        noCourseMessage={
          !category && "Select a course to begin your growth journey"
        }
        noCourseCTA={!category && "Start a Course"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingCourses: state.userState.loadingUserCourse,
    courses: state.userState.courseSubList,
    categories: state.coreState.categories,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSubscribedCourses: (params) =>
      dispatch(getUserSubscribedCourses(params)),
    setCurrentPage: (params) => dispatch(setCurrentMenu(params)),
    loadCategories: () => dispatch(getCategories()),
    setSectionTitle: (params) => dispatch(setSectionTitle(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentCourses);
