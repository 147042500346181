import React from 'react';
const IconOffice = props =>{
 
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path fillRule="evenodd" fill={props.fillColor} clipRule="evenodd" d="M9.97035 21.9407C15.4768 21.9407 19.9407 17.4768 19.9407 11.9704C19.9407 6.46388 15.4768 2 9.97035 2C4.46388 2 0 6.46388 0 11.9704C0 17.4768 4.46388 21.9407 9.97035 21.9407Z" />
      </g>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="20" height="20">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.97035 21.9407C15.4768 21.9407 19.9407 17.4768 19.9407 11.9704C19.9407 6.46388 15.4768 2 9.97035 2C4.46388 2 0 6.46388 0 11.9704C0 17.4768 4.46388 21.9407 9.97035 21.9407Z" fill="white"/>
      </mask>
      <g mask="url(#mask0)">
        <path d="M6.20918 6.85959L11.068 11.0768L9.01507 12.0717L6.05766 6.98499C6.0332 6.94284 6.04279 6.88919 6.08033 6.85813C6.11786 6.82706 6.17236 6.82768 6.20918 6.85959Z" fill="white"/>
        <path d="M13.8834 29.7669C19.3899 29.7669 23.8538 25.303 23.8538 19.7965C23.8538 14.2901 19.3899 9.82617 13.8834 9.82617C8.37696 9.82617 3.91309 14.2901 3.91309 19.7965C3.91309 25.303 8.37696 29.7669 13.8834 29.7669Z" fill="white"/>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="9" width="21" height="21">
          <path d="M13.8834 29.7669C19.3899 29.7669 23.8538 25.303 23.8538 19.7965C23.8538 14.2901 19.3899 9.82617 13.8834 9.82617C8.37696 9.82617 3.91309 14.2901 3.91309 19.7965C3.91309 25.303 8.37696 29.7669 13.8834 29.7669Z" fill="white"/>
        </mask>
        <g mask="url(#mask1)">
          <path d="M10.8696 22.8695C13.2708 22.8695 15.2174 20.9229 15.2174 18.5217C15.2174 16.1204 13.2708 14.1738 10.8696 14.1738C8.46832 14.1738 6.52173 16.1204 6.52173 18.5217C6.52173 20.9229 8.46832 22.8695 10.8696 22.8695Z" fill="url(#paint0_linear)"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d" x="-30" y="-4" width="79.9407" height="79.9407" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="24"/>
          <feGaussianBlur stdDeviation="15"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.038844 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear" x1="-6.34418" y1="10.78" x2="1.13321" y2="36.5118" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D7EDFF"/>
          <stop offset="1" stopColor="white" stopOpacity="0.01"/>
        </linearGradient>
      </defs>
    </svg>
        
  );
  
};
export default IconOffice;
