import React from "react";
import "./CourseItem.scss";
import { LazyImage } from "./LazyLoadImage";
import ProgressBar from "../ProgressBar/ProgressBar";
import { certificateLink } from "../../constants/general";
import { attachAccessTokenToUrlQueryParams } from "../../helpers/url";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hasSubscribedToCourse } from "../../helpers/utility";

const ContinueBtn = ({ course }) => {
  const { subscription = {} } = course;
  const progress = subscription.progress;
  return (
    <>
      <ProgressBar
        className="success-progress"
        courseTotal={(progress || {}).topicRemaining || 0}
        courseCompleted={(progress || {}).completedTopics || 0}
        progress={`${(progress || {}).percentageCompleted || 0}%`}
      />
      <Link
        className={`CourseItemLink ${progress ? "" : "disabled"}`}
        to={{
          pathname: `/classroom/${subscription.id}`,
          state: {
            certificateLink:
              course.has_certificate &&
              certificateLink &&
              certificateLink.length
                ? attachAccessTokenToUrlQueryParams(
                    `${certificateLink}`.replace(":id", subscription.id)
                  )
                : "",
          },
        }}
      >
        <Button
          className={`no-lift btn sme-btn-primary no-border addBtnArrow  `}
        >
          {(progress || {}).percentageCompleted === 0
            ? "Start Course"
            : "Continue course"}
        </Button>
      </Link>
    </>
  );
};

const CourseDetailBtn = ({ course }) => {
  // const {progress} = course.subscription || {};
  return (
    <>
      {/*<ProgressBar className="success-progress" courseTotal={(progress || {}).topicRemaining || 0} courseCompleted={(progress || {}).completedTopics || 0} progress={`${(progress || {}).percentageCompleted || 0}%`} />*/}
      <Link
        to={{ pathname: `/courses/${course.slug}` }}
        title={
          course.course_module_count
            ? course.slug
            : "disabled no course information"
        }
        className={`CourseItemLink ${
          course.course_module_count ? "" : "disabled"
        }`}
      >
        <Button className={`no-lift no-border sme-btn-primary addBtnArrow `}>
          See details & enroll
        </Button>
      </Link>
    </>
  );
};

const CourseItem = ({ course }) => {
  // const canContinue = (course.is_free && course.subscription) || (course.subscription && course.subscription.package_subscription);
  const subscribed = hasSubscribedToCourse(course);

  return (
    <div
      id={course.id}
      className={`card lift  border-0 w-100 h-100 bg-transparent CourseItem`}
    >
      <LazyImage
        src={course.image}
        alt={course.title}
        className={"CourseImage card-img-top"}
      />
      <div className={`card-body d-flex flex-column cardBody text-secondary`}>
        <h2 className={`courseName max-line max-line-2`}>
          {course.title || "Course Name"}
        </h2>
        <p className="card-title">
          {course.description || "Course Description"}
        </p>
        {!subscribed && !course.is_free && (
          <p className="coursePriceTag">
            {formatAmount(course.cost)}
          </p>
        )}
      </div>
      <div className="card-footer border-0">
        <div className="d-flex flex-column justify-content-between contentWrapper">
          {subscribed ? (
            <ContinueBtn course={course} />
          ) : (
            <CourseDetailBtn course={course} />
          )}
        </div>
      </div>
    </div>
  );
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat("en-NG", { style: "currency", currency: "NGN" })
    .format(amount)
    .replace(".00", "");
};

export default CourseItem;
