import './BgSwitch.scss';
import React from 'react';

const BgSwitch = () => {
  const switchAction = event =>{

    // If the user's preference in localStorage is dark...
    if (event.target.checked) {
      // ...let's toggle the .dark-theme class on the body
      document.body.classList.add("dark-theme");
      document.body.classList.remove("light-theme");
      localStorage.setItem("theme", 'dark-theme');
      // Otherwise, if the user's preference in localStorage is light...
    } else {
      // ...let's toggle the .light-theme class on the body
      document.body.classList.add("light-theme");
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", 'light-theme');
    }
    
    // Listen for a click on the button 
    
  };
  return (
    <div className="bgSwitch">
      <input type="checkbox" className="checkbox bgToggle" id="checkbox" onChange={switchAction}  />
      <label htmlFor="checkbox" className="label py-3">
        <i className="fas fa-moon"></i>
        <i className="fas fa-sun"></i>
        <div className={`ball `} ></div>
      </label>
    </div>
  );
};

export default BgSwitch;