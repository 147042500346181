import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
// import { calendarDetails } from '../../constants/general';
import './UpcomingDetails.scss';
import { connect } from 'react-redux';
import { SET_PAGE_BACK_BUTTON, setCurrentMenu } from '../../store/actions/coreActions';
import { smeFormatDate, getValidUrl } from '../../helpers/utility';
import { LazyImage } from '../CourseItem/LazyLoadImage';
import NotFoundPage from '../../containers/pages/NotFound/NotFound';
const UpcomingDetails = props => {
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;
  useEffect(()=>{
    if(!requested){
      dispatch(setCurrentMenu('Calendar'));
      document
        .querySelector('#header #pageTitle .page-title')
        .innerText = 'Back to Upcoming Events';
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: true, location: '/calendar' } });  
      setRequested(true);
    }
  }, [requested, dispatch]);

  return !props.location.state ? <NotFoundPage
    title="Opps!!!"
    subtitle="Sorry, it seems the event you selected doesn't exist or has been moved." 
    goto={`/calendar`}
    cta='Goto Calendar'/> : (
    <Row>
      
      <Col md={8}>
        <Row>
           
          <Col md={12} className="p-0">
            <LazyImage src={props.location.state.event.image } 
              className="img img-fluid w-100 h-100" alt="event-banner"/>
          </Col>
          <Col md={12} className="p-0 mt-3 h-100 d-block d-lg-none">
            <Card style={{border:'none'}} className="eventAction">
              <Card.Body className="text-center ">
                <img width="80" height="71" alt="avatar"
                  src={require('../../assets/img/calendar/cal-action.png')} 
                  className="mx-auto img img-fluid mb-2"/>
                <b className="my-2">Date And Time</b>
      
                <Card.Text className="text-center">
                  {smeFormatDate(props.location.state.event.start_date ).date}
                </Card.Text>
                <Card.Text className="text-center">
                  {smeFormatDate(props.location.state.event.start_date ).time}
                </Card.Text>
                { props.location.state.event.external_link && props.location.state.event.external_link.length ? 
                  <a href={getValidUrl(props.location.state.event.external_link)} target="blank">
                    <Button className="sme-btn-primary lift">
                  Attend this event
                    </Button>
                  </a>
                  : null }
              </Card.Body>
            </Card>
          </Col>
  
          <Col md={12} className="p-0 mt-4">
            <Card style={{border:'none'}} className="eventCardDetails">
             
              <Card.Body className="py-5 px-4">
                <Card.Title>
                  {props.location.state.event.title || ''}
                  
                </Card.Title>
                <hr className="d-block d-lg-none"/>
                <br/>
                <Card.Text dangerouslySetInnerHTML={{__html: props.location.state.event.description || ''}}></Card.Text>
              
              </Card.Body>
            </Card>
           
          </Col>
        </Row>
      </Col>
      <Col md={4} className="h-100 d-none d-lg-block">
        <Card style={{border:'none'}} className="eventAction">
          <Card.Body className="text-center ">
            <img width="80" height="71" alt="avatar"
              src={require('../../assets/img/calendar/cal-action.png')} 
              className="mx-auto img img-fluid mb-2"/>
            <b className="my-2">Date And Time</b>
      
            <Card.Text className="text-center">
              {smeFormatDate(props.location.state.event.start_date ).date}
            </Card.Text>
            <Card.Text className="text-center">
              {smeFormatDate(props.location.state.event.start_date ).time}
            </Card.Text>
            { props.location.state.event.external_link && props.location.state.event.external_link.length ? 
              <a href={getValidUrl(props.location.state.event.external_link)} target="blank">
                <Button className="sme-btn-primary lift">
                  Attend this event
                </Button>
              </a>
              : null }
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => {
  return {
    event: state.eventState
  };
};
export default connect(mapStateToProps)(UpcomingDetails);