import * as actionType from "../actions/userActions";
import * as actionTypes from "../actions/userActions";

const initialState = {
  user: null,
  loadingUserCourse: true,
  courseList: [],
  courseSubList: null,
  pendingCourseCount: 0,
  completedCourseCount: 0,
  currentSubscription: null
};
let updatedCourseList;
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOADING_COURSE:
      return{
        ...state,
        loadingUserCourse: action.payload.loadingUserCourse
      };
    case actionType.COMPLETED_COURSE_COUNT:
      return{
        ...state,
        completedCourseCount: action.payload.completedCourseCount,
      };
    case actionType.PENDING_COURSE_COUNT:
      return{
        ...state,
        pendingCourseCount: action.payload.pendingCourseCount,
      };
    case actionType.SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case actionType.SET_COURSE_LIST:
      return {
        ...state,
        courseList: action.payload.courseList,
        loadingUserCourse: action.payload.loadingUserCourse,
      };
    case actionTypes.SET_COURSE_LIST_WITH_SUB:
      return {
        ...state,
        loadingUserCourse: action.payload.loadingUserCourse,
        courseSubList: action.payload.courseSubList
      };
    case actionType.UPDATE_SUB:
      state.loadingUserCourse = action.payload.loadingUserCourse;
      updatedCourseList = state.courseList.courses
        .concat([ ...action.payload.courseList.courses]);
      return {
        ...state,
        courseList:{
          ...state.courseList,
          courses: updatedCourseList,
          next_page: action.payload.courseList.next_page,
          number_of_pages: action.payload.courseList.number_of_pages,
          total: action.payload.courseList.total,
          limit: action.payload.courseList.limit,
          page: action.payload.courseList.page
        }
      };
    case actionType.SET_CURRENT_COURSE:
      return {
        ...state,
        currentSubscription: action.payload.currentSubscription
      };
    case actionType.UPDATE_USER_BUSINESS:
      return {
        ...state,
        user:{
          ...state.user,
          business_information: { ...action.payload.business_information }
        }
      };
    case actionType.FETCH_USER_DISCUSSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          discussions: action.payload.discussions
        }
      };
    default:
      return state;
  }
};

export default userReducer;
