import React from "react";
import Loader from "../../components/Loader/Loader";

const pageLoader = props => {
  return (
    <div className="min-content-height d-flex justify-content-center my-auto align-content-center ">
      <Loader type={props.type || "ripple"} scale={props.scale}/>
    </div>
  );
};

export default pageLoader;
