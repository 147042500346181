import stepOne from '../assets/img/onboarding/screen-1-image.png';

import iconOne from '../assets/img/onboarding/onboarding-home-icon.png';
import iconTwo from '../assets/img/onboarding/onboarding-course-icon.png';
import iconThree from '../assets/img/onboarding/onboarding-course-icon.png';
// import iconFour from '../assets/img/onboarding/onboarding-course-icon.png';
// import iconFive from '../assets/img/onboarding/onboarding-course-icon.png';
// import iconSix from '../assets/img/onboarding/onboarding-course-icon.png';
import { POSTION_MODAL_TOP_LEFT, POSTION_MODAL_CENTER } from '../store/actions/walkthroughAction';
export const onBoardingData = 
{
  dashboardOnboarding: [
    {
      bg: 'yellowBackground',
      image: stepOne,
      caption: `We can't wait for you to get started on SME shop and start
         gaining the skills and knowledge to grow your business but first,
          a tour to give you tips on using the platform. `,
      hasBackground: true,
      activateMenu: 'none',
      modalPosition: POSTION_MODAL_CENTER
    },
    {
      bg: '',
      icon: iconOne,
      image: 'onboarding/onboarding-home-icon.png',
      title: 'Home Dashboard',
      caption: `Easily view the overview of your program journey,
       milestones, office hour sessions and quick access to your ongoing courses. `,
      hasBackground: false,
      activateMenu: 'dashboard',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconTwo,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'My Courses',
      caption: `This is the classroom where you can take all 
      your courses and get access to resources. `,
      hasBackground: false,
      activateMenu: 'courses',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconThree,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'Calendar',
      caption: `You can register for and see all the information
       about upcoming events, workshops and webinars at the SME Shop.`,
      hasBackground: false,
      activateMenu: 'calendar',
      marginTop: '22vh',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconThree,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'Community',
      caption: `This is the online community where you can discuss
       with other SME Shop users on any of your courses and get answers 
       to business related questions. `,
      hasBackground: false,
      activateMenu: 'community',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconThree,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'Profile',
      caption: `Update your personal and business information here.`,
      hasBackground: false,
      activateMenu: 'profile',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconThree,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'Settings',
      caption: ` You can change your password and upgrade your consultation plan here.`,
      hasBackground: false,
      activateMenu: 'settings',
      modalPosition: POSTION_MODAL_TOP_LEFT
    },
    {
      bg: '',
      icon: iconThree,
      image: 'onboarding/onboarding-course-icon.png',
      title: 'Logout',
      caption: ` Click here to logout of the classroom.`,
      hasBackground: false,
      activateMenu: 'logout',
      modalPosition: POSTION_MODAL_TOP_LEFT
    }
    
  ]
  
};