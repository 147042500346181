import apiRoutes from "./apiRoutes";
import { createMuiTheme } from "@material-ui/core";
import { replaceDynamics } from "../helpers/url";
import dashboardIcon from '../assets/icons/sidebar/home.svg';
import courseIcon from '../assets/icons/sidebar/courses.svg';

import calendarIcon from '../assets/icons/sidebar/calendar.svg';
import communityIcon from '../assets/icons/sidebar/community.svg';
import profileIcon from '../assets/icons/sidebar/profile.svg';
import settingIcon from '../assets/icons/sidebar/settings.svg';
import logoutIcon from '../assets/icons/sidebar/logout.svg';
import blackTick from '../assets/icons/black-tick.png';
import whiteTick from '../assets/icons/white-tick.png';
import fadedTick from '../assets/icons/faded-tick.png';

export const nigerianStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara"
];
export const positionList = [
  'Director',
  'Founder/Owner',
  'C-Level Executive (CFO, CSO, COO etc)',
  'Manager',
  'Team Lead',
  'Other'
];
export const industryList = [
  'Agriculture',
  'Advertising/Marketing/Entertainment ',
  'Retail',
  'Construction/Real estate',
  'Healthcare',
  'Industrial goods',
  'ICT/Technology',
  'Natural resources',
  'Oil and gas',
  'Services'
];
export const NOTIFICATION_TYPES = {
  DISCUSSION_NOTIFICATION: 801,
  PAYMENT_NOTIFICATION: 802
};
export const emailNotification = `Thanks for registering, Kindly activate your account, 
we sent you an email. Thanks!`;
export const calendarDetails = {
  title: 'Paint and Sip in Lagos Event',
  subTitle: `Are you thinking of a fun thing to do on the 29th of August? 
  Then you should try out our virtual paint and sip party
  `,
  body:`Are you thinking of a fun thing to do on the 29th of August? 
  Then you should try out our virtual paint and sip party 🥰 Once you purchase this 
  product you would get our paint pack and would join the webinar. A paint instructor would
   be around to show you how to paint as well and give step by step instructions to create 
   your master piece.
  To purchase this pack click on this link: https://flutterwave.com/store/justpaintng
  Items in the paint and sip party pack:
  Paint brushes:these are brushes you use to apply the paints on the canvas
  the canvas:this is the platform on which you paint your pictures on
  Palette: this is a little flat plate in which you mix your colors you want to use in pain
  acrylic paint-the paint used in achieving the desired picture
  wine
  small chops
  All of these above listed items are available in the paint and sip pack that are
   available for sale for our virtual paint party this Saturday and they go for N10,000
    including delivery charges in LAGOS. For more inquiries 
    contact: 08188658051 or @justpaint_ng on Instagram & Twitter`
};

export const authEndpoints = {
  localAuth: `${process.env.REACT_APP_BASE_API_URI}auth/sign-in`,
  google: `${process.env.REACT_APP_BASE_API_URI}${apiRoutes.USER_AUTH_GOOGLE}`,
  facebook: `${process.env.REACT_APP_BASE_API_URI}${apiRoutes.USER_AUTH_FACEBOOK}`
};

export const paystackPaymentLink = `${
  process.env.REACT_APP_BASE_API_URI
}${
  replaceDynamics(apiRoutes.SUBSCRIBE, {paymentMethod: 'paystack'})
}`;
export const defaultPriceContents = [
  {
    type: 'Basic',
    price: '150,000',
    btnClass:
      'sme-btn-dark btn  font-w-mbd  align-self-center',
    cardClass:
      `lift grey-price-card container d-flex flex-column 
      justify-content-center align-items-start p-5`,
    plan: 'basic',
    textColor: 'text-dark',
    items: [
      {
        icon: blackTick,
        text: 'Business diagnosis and recommendations',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Business analysis and modelling',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Brand book',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Logo Design and identity design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Business Card Design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Letterhead template',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Business envelope',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Invoice design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Label design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Social Media identity design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Development of a Communication strategy',
        class: 'text-muted'
      }
    ]
  },
  {
    type: 'Professional',
    price: '300,000',
    btnClass:
      'sme-btn-primary btn btn-primary font-w-mbd  align-self-center',
    cardClass:
      `lift black-price-card container d-flex flex-column
       justify-content-center align-items-start p-5`,
    plan: 'pro',
    textColor: 'text-white',
    items: [
      {
        icon: whiteTick,
        text: 'Business diagnosis and recommendations',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Business analysis and modelling',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Brand book',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Logo Design and identity design',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Business Card Design',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Letterhead template',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Business envelope',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Invoice design',
        class: 'text-white'
      },
      {
        icon: whiteTick,
        text: 'Label design',
        class: 'text-white'
      },
      {
        icon: fadedTick,
        text: 'Social Media identity design',
        class: 'text-muted'
      },
      {
        icon: fadedTick,
        text: 'Development of a Communication strategy',
        class: 'text-muted'
      }
    ]
  },
  {
    type: 'Classic',
    price: '500,000',
    textColor: 'text-dark',
    btnClass:
      'sme-btn-dark btn font-w-mbd  align-self-center',
    cardClass:
      `lift container yellow-price-card d-flex 
      flex-column justify-content-center align-items-start p-5`,
    plan: 'classic',
    items: [
      {
        icon: blackTick,
        text: 'Business diagnosis and recommendations',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Business analysis and modelling',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Brand book',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Logo Design and identity design',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Business Card Design',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Letterhead template',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Business envelope',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Invoice design',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Label design',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Social Media identity design',
        class: 'text-black'
      },
      {
        icon: blackTick,
        text: 'Development of a Communication strategy',
        class: 'text-black'
      }
    ]
  }
];
export const certificateLink = `
${process.env.REACT_APP_BASE_API_URI}/user-subscriptions/certificate/download/:id/
?redirectUrl=${process.env.REACT_APP_CLASSROOM_SERVICE_URL}/dashboard`;

export const ACTIVATION_LINK = `${process.env.REACT_APP_CLASSROOM_SERVICE_URL}/dashboard`;
export const NETWORK_ERROR_MESSAGE = "Network Error: Please check your internet";

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0E0E0E'
    },
    secondary: {
      main: '#FFE001'
    }
  }
});

export const courseCategories = [
  {
    "name": "All",
    "link": "/courses",
    "banner": "ctg_all_banner.jpg"
  },
  {
    "name": "Digital Marketing",
    "id": "marketing",
    "img": "ctg_marketing_icon.svg",
    "banner": "ctg_marketing_banner.jpg",
    "link": "/courses?category=marketing"
  },
  {
    "name": "Software Development",
    "id": "software",
    "img": "ctg_software_icon.svg",
    "banner": "ctg_software_banner.jpg",
    "link": "/courses?category=software"
  },
  {
    "name": "Design",
    "id": "design",
    "img": "ctg_design_icon.svg",
    "banner": "ctg_design_banner.jpg",
    "link": "/courses?category=design"
  },
  {
    "name": "Data Science",
    "id": "data",
    "img": "ctg_data_icon.svg",
    "banner": "ctg_data_banner.jpg",
    "link": "/courses?category=data"
  }
];

export const headerLinks = [
  {id: "courses", name: "Courses", routerLink: "/courses"},
  {id: "aboutUs", name: "About Us", routerLink: "/content/about-us"},
  {id: "contactUs", name: "Contact Us", routerLink: "/content/contact-us"}
];
export const sideBarLinks = {
  'Main Menu': [
    {c: 1, id: "dashboard", name: "Dashboard", routerLink: "/", icon:dashboardIcon},
    {c: 2,id: "courses", name: "My Courses", routerLink: "/courses", icon:courseIcon},

    {c: 3, id: "calendar", name: "My Calendar",
      routerLink: "/calendar", icon:calendarIcon},
    {c: 4, id: "community", name: "Community",
      routerLink: "/community", icon:communityIcon}
  ],
  'Settings':[
    {c: 5, id: "profile", name: "Profile", routerLink: "/profile", icon:profileIcon},
    {c: 6,id: "settings", name: "Settings", routerLink: "/settings", icon:settingIcon},
    {c: 7,id: "logout", name: "Logout", routerLink: `/logout`, icon:logoutIcon}
  ]
};

export const footerLinks = [
  {id: "privacy", name: "Privacy", routerLink: "/content/privacy"},
  {id: "terms", name: "Terms", routerLink: "/content/terms"},
  ...headerLinks
];

export const regularLinks = [
  ...footerLinks
];

export const socialSharer = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=@url',
  twitter: 'https://twitter.com/intent/tweet?url=@url',
  linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=@url'
};
