import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import './ProfileEdit.scss';
import { connect } from "react-redux";
import {setCurrentMenu, SHOW_SNACKBAR} from '../../store/actions/coreActions';
import { disableForm } from '../../helpers/utility';
import apiRoutes from '../../constants/apiRoutes';
import http from '../../helpers/http';
import Loader from '../Loader/Loader';

const SideMenu = ()=>(
  <Card className="border-0 mt-4 rounded">
    
    <Card.Body >
      <Card.Title className="profile mb-4">
       My Settings
      </Card.Title>
      <p className="small-menu-li">Change Password</p>
    </Card.Body>
  </Card>
);

const PasswordEdit = (props) => {
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;

  useEffect(() => {
    if(!requested) {
      dispatch(setCurrentMenu('settings'));
      setRequested(true);
    }
  }, [ requested, dispatch]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const toggleForm = e => {
    const text = !disabled ? 'Edit Password' : 'Cancel';
    e.target.innerText= text;
    setDisabled(!disabled);
    disableForm('form.profileEdit  .form-control', disabled);
  };

  const saveProfileInformation = (event )=>{
    event.preventDefault();
    const data = new FormData(event.target);
    let formData = {};
    for(let pair of data.entries()) {
      formData[pair[0]] = pair[1];
    }
    setIsLoading(true);
    http.put(apiRoutes.USER_EDIT_PASSWORD, formData).then(() => {
      setIsLoading(false);
      props.dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: "Password Updated."}
      } });
    }).catch(()=>{
      setIsLoading(false);
      props.dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: 'Invalid password or username'}
      } });
    });

  };
  const passwordStrength = (event)=>{
    const myInput = event.target;
    const lowerCaseLetters = /[a-z]/g;
    if(myInput.value.match(lowerCaseLetters)) {
      event.target.classList.remove("error");
      event.target.classList.add("success");
    } else {
      event.target.parentElement.children[0].children.innerHTML = 'Must contain a lowercase';
      event.target.classList.remove("success");
      event.target.classList.add("error");
    }

    // Validate capital letters
    // const upperCaseLetters = /[A-Z]/g;
    // if(myInput.value.match(upperCaseLetters)) {
    //   event.target.classList.remove("has-error");
    //   event.target.classList.add("has-error");
    // } else {
    //   capital.classList.remove("valid");
    //   capital.classList.add("invalid");
    // }

    // // Validate numbers
    // var numbers = /[0-9]/g;
    // if(myInput.value.match(numbers)) {
    //   event.target.classList.remove("has-error");
    //   event.target.classList.add("has-error");
    // } else {
    //   number.classList.remove("valid");
    //   number.classList.add("invalid");
    // }

    // // Validate length
    // if(myInput.value.length >= 8) {
    //   length.classList.remove("invalid");
    //   length.classList.add("valid");
    // } else {
    //   length.classList.remove("valid");
    //   length.classList.add("invalid");
    // }
  };
  return (
    <Row>
      
      <Col md={12} sm={12} lg={8}>
        <Card className="border-0 mt-4">
          <Card.Body>
            <Card.Title className="profile mb-4 d-flex flex-columns justify-content-between align-items-center">
              <span>Change Password</span>
              <span><Button variant="link" style={{textDecoration: 'none', color:'#111'}} className="no-lift sme-btn-grey" onClick={toggleForm}> Edit Password </Button></span>
            </Card.Title>
            <Form onSubmit={saveProfileInformation} className="profileEdit form">
              <Form.Group controlId="formCurrentPassword">
                <Form.Label>Current Password</Form.Label>
                {/* <div className="position-relative">
                  <div className="formMessage error" id="nameError">
                    <span className="text-center"> </span>
                  </div> */}
                <Form.Control disabled={disabled}  type="password" name="currentPassword"  placeholder="Password" />
                {/* </div> */}
                
              </Form.Group>
              <Form.Group controlId="formNewPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control disabled={disabled}  type="password" name="newPassword" placeholder="New Password" />
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control disabled={disabled} onKeyDown={passwordStrength} type="password" name="confirmPassword" placeholder="Confirm Password" />
              </Form.Group>
              <Button disabled={disabled } className="no-border sme-btn-primary px-4  py-2 mt-5 d-flex align-items-center flex-columns" type="submit">
                          Update Password
                <span className="ml-1">{isLoading ? <Loader scale="0.3"/> : '' }</span>
              </Button>
            </Form>
          </Card.Body>
        </Card>
 
      </Col>
      <Col md={4} className="d-none d-lg-block">
        {SideMenu()}
      </Col>
    </Row>
  );
};
const mapStateToProps = state => {
  return {
    student: state.userState.user
  };
};
export default connect(mapStateToProps)(PasswordEdit);