import React, { Fragment } from "react";
import moment from 'moment';
import './CalendarWidget.scss';
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const SelectList = (props) => {
  let popup = props.data.map((data) => {
    return (
      <div key={data}>
        <button className="convertButtonToLink no-border"  onClick={(e)=> {props.onSelectChange(e, data);}}>
          {data}
        </button>
      </div>
    );
  });

  return (
    <div className="month-popup">
      {popup}
    </div>
  );
};
export default class CalendarWidget extends React.Component {
  weekdayshort = moment.weekdaysShort();
  state = {
    dateContext: moment(),
    today: moment(),
    showMonthPopup: false,
    showYearPopup: false,
    selectedDay: null
  }
  componentDidMount(){
    document.querySelector('#header #pageTitle .page-title').innerText = 'Calendar';
  }
  
    weekdays = moment.weekdays(); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
    weekdaysShort = moment.weekdaysShort(); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    months = moment.months();

    year = () => {
      return this.state.dateContext.format("Y");
    }
    month = () => {
      return this.state.dateContext.format("MMMM");
    }

    currentDayLabel = (date) => {
      const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
      return dt.format('ddd');
    }
    daysInMonth = () => {
      return this.state.dateContext.daysInMonth();
    }
    currentDate = (field = "date") => {
      return this.state.dateContext.format(field);
    }
    currentDay = () => {
      return this.state.dateContext.format("D");
    }

    firstDayOfMonth = () => {
      let dateContext = this.state.dateContext;
      let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
      return firstDay;
    }

    setMonth = (month) => {
      
      let monthNo = this.months.indexOf(month);
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).set("month", monthNo);
      this.setState({
        dateContext: dateContext
      });
    }
    setCalandarMonth=(event)=>{
      const { month } = event.target.dataset;
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment().month(month); 
      this.setState({
        dateContext: dateContext
      });
    }
    nextMonth = () => {
      let dateContext = Object.assign({}, this.state.dateContext);
      dateContext = moment(dateContext).add(1, "month");
      this.setState({
        dateContext: dateContext
      });
      this.props.onNextMonth && this.props.onNextMonth();
    }

  prevMonth = () => {
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).subtract(1, "month");
    this.setState({
      dateContext: dateContext
    });
    this.props.onPrevMonth && this.props.onPrevMonth();
  }

     onSelectChange = (e, data) => {
       this.setMonth(data);
       this.props.onMonthChange && this.props.onMonthChange();
     }
  

  onChangeMonth = (e, month) => {
    this.setState({
      showMonthPopup: !this.state.showMonthPopup
    });
  }

  MonthNav = () => {
    return (
      <span className="label-month mr-2"
        onClick={(e)=> {this.onChangeMonth(e, this.month());}}>
        {this.month()}
        {this.state.showMonthPopup &&
            <SelectList data={this.months} onSelectChange={ this.onSelectChange }/>
        }
      </span>
    );
  }

  showYearEditor = () => {
    this.setState({
      showYearNav: true
    });
  }

  setYear = (year) => {
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).set("year", year);
    this.setState({
      dateContext: dateContext
    });
  }
  onYearChange = (e) => {
    this.setYear(e.target.value);
    this.props.onYearChange && this.props.onYearChange(e, e.target.value);
  }

  onKeyUpYear = (e) => {
    if (e.which === 13 || e.which === 27) {
      this.setYear(e.target.value);
      this.setState({
        showYearNav: false
      });
    }
  }

  YearNav = () => {
    return (
      this.state.showYearNav ?
        <input
          defaultValue = {this.year()}
          className="editor-year"
          ref={(yearInput) => { this.yearInput = yearInput;}}
          onKeyUp= {(e) => this.onKeyUpYear(e)}
          onChange = {(e) => this.onYearChange(e)}
          type="number"
          placeholder="year"/>
        :
        <span
          className="label-year mr-3"
          onDoubleClick={(e)=> { this.showYearEditor();}}>
          {this.year()}
        </span>
    );
  }

  onDayClick = (e, day) => {
    this.setState({
      selectedDay: day
    }, () => {
      console.log("SELECTED DAY: ", this.state.selectedDay);
    });

    this.props.onDayClick && this.props.onDayClick(e, day);
  }
  render() {
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      if(window.innerWidth > 800) {
        blanks.push(<td key={i * 80} className="emptySlot">
          {""}
        </td>);
      }else{
        blanks.push(<div key={i * 80} className="emptySlot">
          {""}
        </div>);
      }
      
      
    }

    let daysInMonth = [];
    if(!this.props.events.allEvents.loading){
      
      for (let d = 1; d <= this.daysInMonth(); d++) {
        let className = (d === this.currentDay() ? "dayDesktop current-dayDesktop": "dayDesktop");
        let currentDay = (d === this.currentDay() ? "current-dayDesktop": "");
        let selectedClass = (d === this.state.selectedDay ? " selected-day " : "");
        let event = [];
        event = this.props.events.allEvents.events.map((item, i)=>{
          const formatEventDate = moment(item.start_date).format(moment.HTML5_FMT.DATE);
          const calendarDate = moment(`${this.state.dateContext.format("Y")}-${this.state.dateContext.format("M")}-${d}`, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format(moment.HTML5_FMT.DATE);
          if( moment(calendarDate).isSame(formatEventDate)){
            return (<p className={`${calendarDate === 'Invalid date' ? 'invalidDate' : ''} eventDataDesktop p-1 mb-2 `} key={`eventData-${i}`}><Link  to={{
              pathname: `/calendar/${item.slug}`,
              state: {event:  {...item}}
            }} key={i}>{item.title}</Link></p>);
          }
          return <Fragment key={i}></Fragment>;
          
        });
      
        daysInMonth.push(
          <td key={d} className={`${className} ${selectedClass} `} >
            <div className="datesDesktop mb-2"> 
              <span className={currentDay}  onClick={(e)=>{this.onDayClick(e, d);}}>{d}</span>
              <span onClick={(e)=>{this.onDayClick(e, d);}}>{this.currentDayLabel(`${this.year()}-${this.currentDate('MM')}-${d}`)}</span>
            </div>
            <div className="eventDataGroup h-80">
              { event }
            </div>
          </td>
        );
         

        
      }
    }

    
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if ((i % 7) !== 0) {
        cells.push(row);
      } else {
        let insertRow = cells.slice();
        rows.push(insertRow);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice();
        rows.push(insertRow);
      }
    });

    let trElems =  
      rows.map((d, i) => {
        return (
          <tr key={i*100}>
            {d}
          </tr>
        );
      });

    return (
      <Row className="d-none d-lg-block">
        <Col md={12} className="p-0">
          <div className="mt-5 bg-white">
            <div className="d-flex flex-columns py-4">
              <span 
                className="mx-3 calendarIcon"
                alt="calendarIcon" height="33" width="33" >
              </span>
              {this.MonthNav()}
              {this.YearNav() }

              {/* NEXT BUTTON */}
              <span height="33" width="33" 
                onClick={(e) => { this.prevMonth(e); }} data-type="reverse" alt="backButton" className="lift prevButton"></span>
              {/* PREVIOUS BUTTON     */}
              <span height="33" width="33" 
                onClick={(e) => { this.nextMonth(e); }} data-type="forward" alt="nextButton" className="lift nextButton"></span>
              <button onClick={(e) => this.setCalandarMonth(e)} data-month={this.months[new Date().getMonth()]}className="todayButton px-4 py-2 ml-3 lift d-none d-lg-block"> THIS MONTH</button>
            </div>
            <div>
              <table className="table table-sm">
                <tbody>
                  {trElems}
                </tbody>
              </table>
            </div>
           
        
           
          </div>
        </Col>
      </Row>
    );
  }
}