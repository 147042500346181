import axios from 'axios';
import cookie from "../helpers/cookie";
import apiRoutes from "../constants/apiRoutes";
import { updateQueryParams } from "../helpers/url";
import {logOut} from "../helpers/utility";

const jwtErrorInterceptor = (error, http) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401
    && error.response.data.subCode === 601
    && !originalRequest._retry) {
    const rfToken = cookie.get('refreshToken');

    if (!rfToken) {
      logOut();
      return Promise.resolve();
    }

    originalRequest._retry = true;

    return http.get(updateQueryParams(apiRoutes.REFRESH_TOKEN, {refreshToken: rfToken}))
      .then(res => {
        if (res.status === 200) {
          const {accessToken, refreshToken} = res.data;
          if(accessToken) {
            cookie.put('accessToken', accessToken);
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
          }
          if(refreshToken) {
            cookie.put('refreshToken', refreshToken);
          }
          return axios(originalRequest);
        }
      }).catch(rtError => {
        if(rtError.response && rtError.response.status === 401
          && rtError.response.data.subCode === 604) {
          logOut();
        }
      });
  }
  return Promise.reject(error);
};

export default jwtErrorInterceptor;
